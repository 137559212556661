import { Component } from '@angular/core';
import { faBan } from '@fortawesome/pro-solid-svg-icons';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'lukla-unauthorized',
  standalone: true,
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
  imports: [SharedModule]
})
export class UnauthorizedComponent {
  noAccessIcon = faBan;
}
