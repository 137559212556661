/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { attachProjectAsset } from '../fn/project/attach-project-asset';
import { AttachProjectAsset$Params } from '../fn/project/attach-project-asset';
import { attachProjectAssets } from '../fn/project/attach-project-assets';
import { AttachProjectAssets$Params } from '../fn/project/attach-project-assets';
import { attachProjectMethodology } from '../fn/project/attach-project-methodology';
import { AttachProjectMethodology$Params } from '../fn/project/attach-project-methodology';
import { createProject } from '../fn/project/create-project';
import { CreateProject$Params } from '../fn/project/create-project';
import { detachAssetFromProject } from '../fn/project/detach-asset-from-project';
import { DetachAssetFromProject$Params } from '../fn/project/detach-asset-from-project';
import { getProject } from '../fn/project/get-project';
import { GetProject$Params } from '../fn/project/get-project';
import { getProjectAssets } from '../fn/project/get-project-assets';
import { GetProjectAssets$Params } from '../fn/project/get-project-assets';
import { getProjectIdentifiers } from '../fn/project/get-project-identifiers';
import { GetProjectIdentifiers$Params } from '../fn/project/get-project-identifiers';
import { getProjectValidation } from '../fn/project/get-project-validation';
import { GetProjectValidation$Params } from '../fn/project/get-project-validation';
import { listProjects } from '../fn/project/list-projects';
import { ListProjects$Params } from '../fn/project/list-projects';
import { listReportingPeriods } from '../fn/project/list-reporting-periods';
import { ListReportingPeriods$Params } from '../fn/project/list-reporting-periods';
import { Response as LuklaCreditingGetProjectPeriodsResponse } from '../models/Lukla/Crediting/GetProjectPeriods/response';
import { IdentifierView as LuklaCreditingIdentifierView } from '../models/Lukla/Crediting/identifier-view';
import { PeriodAssetItem as LuklaCreditingPeriodAssetItem } from '../models/Lukla/Crediting/period-asset-item';
import { ProjectPhaseItem as LuklaCreditingProjectPhaseItem } from '../models/Lukla/Crediting/project-phase-item';
import { ProjectSummaryView as LuklaCreditingProjectSummaryView } from '../models/Lukla/Crediting/project-summary-view';
import { ProjectView as LuklaCreditingProjectView } from '../models/Lukla/Crediting/project-view';
import { ValidationView as LuklaCreditingValidationView } from '../models/Lukla/Crediting/validation-view';
import { moveIdentifier } from '../fn/project/move-identifier';
import { MoveIdentifier$Params } from '../fn/project/move-identifier';
import { projectPhases } from '../fn/project/project-phases';
import { ProjectPhases$Params } from '../fn/project/project-phases';
import { projectSummary } from '../fn/project/project-summary';
import { ProjectSummary$Params } from '../fn/project/project-summary';
import { removeIdentifier } from '../fn/project/remove-identifier';
import { RemoveIdentifier$Params } from '../fn/project/remove-identifier';
import { removeProject } from '../fn/project/remove-project';
import { RemoveProject$Params } from '../fn/project/remove-project';
import { updateProject } from '../fn/project/update-project';
import { UpdateProject$Params } from '../fn/project/update-project';
import { upsertIdentifier } from '../fn/project/upsert-identifier';
import { UpsertIdentifier$Params } from '../fn/project/upsert-identifier';

@Injectable({ providedIn: 'root' })
export class ProjectService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createProject()` */
  static readonly CreateProjectPath = '/projects/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProject()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createProject$Response(params?: CreateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createProject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createProject$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createProject(params?: CreateProject$Params, context?: HttpContext): Observable<string> {
    return this.createProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getProject()` */
  static readonly GetProjectPath = '/projects/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProject$Response(params: GetProject$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingProjectView>> {
    return getProject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProject(params: GetProject$Params, context?: HttpContext): Observable<LuklaCreditingProjectView> {
    return this.getProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingProjectView>): LuklaCreditingProjectView => r.body)
    );
  }

  /** Path part for operation `listProjects()` */
  static readonly ListProjectsPath = '/projects/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  listProjects$Response(params?: ListProjects$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingProjectView>>> {
    return listProjects(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listProjects(params?: ListProjects$Params, context?: HttpContext): Observable<Array<LuklaCreditingProjectView>> {
    return this.listProjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingProjectView>>): Array<LuklaCreditingProjectView> => r.body)
    );
  }

  /** Path part for operation `projectSummary()` */
  static readonly ProjectSummaryPath = '/projects/{projectId}/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectSummary$Response(params: ProjectSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingProjectSummaryView>> {
    return projectSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectSummary(params: ProjectSummary$Params, context?: HttpContext): Observable<LuklaCreditingProjectSummaryView> {
    return this.projectSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingProjectSummaryView>): LuklaCreditingProjectSummaryView => r.body)
    );
  }

  /** Path part for operation `listReportingPeriods()` */
  static readonly ListReportingPeriodsPath = '/projects/{projectId}/periods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listReportingPeriods()` instead.
   *
   * This method doesn't expect any request body.
   */
  listReportingPeriods$Response(params: ListReportingPeriods$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingGetProjectPeriodsResponse>> {
    return listReportingPeriods(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listReportingPeriods$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listReportingPeriods(params: ListReportingPeriods$Params, context?: HttpContext): Observable<LuklaCreditingGetProjectPeriodsResponse> {
    return this.listReportingPeriods$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingGetProjectPeriodsResponse>): LuklaCreditingGetProjectPeriodsResponse => r.body)
    );
  }

  /** Path part for operation `projectPhases()` */
  static readonly ProjectPhasesPath = '/projects/{projectId}/phases';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectPhases()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectPhases$Response(params: ProjectPhases$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingProjectPhaseItem>>> {
    return projectPhases(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectPhases$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  projectPhases(params: ProjectPhases$Params, context?: HttpContext): Observable<Array<LuklaCreditingProjectPhaseItem>> {
    return this.projectPhases$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingProjectPhaseItem>>): Array<LuklaCreditingProjectPhaseItem> => r.body)
    );
  }

  /** Path part for operation `getProjectValidation()` */
  static readonly GetProjectValidationPath = '/projects/{projectId}/validation';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProjectValidation()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectValidation$Response(params: GetProjectValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingValidationView>> {
    return getProjectValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProjectValidation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectValidation(params: GetProjectValidation$Params, context?: HttpContext): Observable<LuklaCreditingValidationView> {
    return this.getProjectValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingValidationView>): LuklaCreditingValidationView => r.body)
    );
  }

  /** Path part for operation `getProjectAssets()` */
  static readonly GetProjectAssetsPath = '/projects/{projectId}/assets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProjectAssets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectAssets$Response(params: GetProjectAssets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingPeriodAssetItem>>> {
    return getProjectAssets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProjectAssets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectAssets(params: GetProjectAssets$Params, context?: HttpContext): Observable<Array<LuklaCreditingPeriodAssetItem>> {
    return this.getProjectAssets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingPeriodAssetItem>>): Array<LuklaCreditingPeriodAssetItem> => r.body)
    );
  }

  /** Path part for operation `attachProjectMethodology()` */
  static readonly AttachProjectMethodologyPath = '/projects/methodology';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachProjectMethodology()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachProjectMethodology$Response(params?: AttachProjectMethodology$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attachProjectMethodology(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attachProjectMethodology$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachProjectMethodology(params?: AttachProjectMethodology$Params, context?: HttpContext): Observable<void> {
    return this.attachProjectMethodology$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `attachProjectAsset()` */
  static readonly AttachProjectAssetPath = '/projects/assets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachProjectAsset()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachProjectAsset$Response(params?: AttachProjectAsset$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attachProjectAsset(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attachProjectAsset$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachProjectAsset(params?: AttachProjectAsset$Params, context?: HttpContext): Observable<void> {
    return this.attachProjectAsset$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `attachProjectAssets()` */
  static readonly AttachProjectAssetsPath = '/projects/assets/bulk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachProjectAssets()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachProjectAssets$Response(params?: AttachProjectAssets$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attachProjectAssets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attachProjectAssets$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachProjectAssets(params?: AttachProjectAssets$Params, context?: HttpContext): Observable<void> {
    return this.attachProjectAssets$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateProject()` */
  static readonly UpdateProjectPath = '/projects/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProject()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateProject$Response(params?: UpdateProject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateProject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProject$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateProject(params?: UpdateProject$Params, context?: HttpContext): Observable<void> {
    return this.updateProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeProject()` */
  static readonly RemoveProjectPath = '/projects/{projectId}/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeProject()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeProject$Response(params: RemoveProject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeProject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeProject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeProject(params: RemoveProject$Params, context?: HttpContext): Observable<void> {
    return this.removeProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getProjectIdentifiers()` */
  static readonly GetProjectIdentifiersPath = '/projects/{projectId}/identifiers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProjectIdentifiers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectIdentifiers$Response(params: GetProjectIdentifiers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingIdentifierView>>> {
    return getProjectIdentifiers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProjectIdentifiers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectIdentifiers(params: GetProjectIdentifiers$Params, context?: HttpContext): Observable<Array<LuklaCreditingIdentifierView>> {
    return this.getProjectIdentifiers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingIdentifierView>>): Array<LuklaCreditingIdentifierView> => r.body)
    );
  }

  /** Path part for operation `upsertIdentifier()` */
  static readonly UpsertIdentifierPath = '/projects/upsert-identifier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertIdentifier()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertIdentifier$Response(params?: UpsertIdentifier$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return upsertIdentifier(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertIdentifier$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertIdentifier(params?: UpsertIdentifier$Params, context?: HttpContext): Observable<string> {
    return this.upsertIdentifier$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `removeIdentifier()` */
  static readonly RemoveIdentifierPath = '/projects/remove-identifier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeIdentifier()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeIdentifier$Response(params?: RemoveIdentifier$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeIdentifier(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeIdentifier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeIdentifier(params?: RemoveIdentifier$Params, context?: HttpContext): Observable<void> {
    return this.removeIdentifier$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `moveIdentifier()` */
  static readonly MoveIdentifierPath = '/projects/move-identifier';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveIdentifier()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  moveIdentifier$Response(params?: MoveIdentifier$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return moveIdentifier(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveIdentifier$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  moveIdentifier(params?: MoveIdentifier$Params, context?: HttpContext): Observable<string> {
    return this.moveIdentifier$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `detachAssetFromProject()` */
  static readonly DetachAssetFromProjectPath = '/projects/assets/detach';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `detachAssetFromProject()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  detachAssetFromProject$Response(params?: DetachAssetFromProject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return detachAssetFromProject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `detachAssetFromProject$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  detachAssetFromProject(params?: DetachAssetFromProject$Params, context?: HttpContext): Observable<void> {
    return this.detachAssetFromProject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
