import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.identityEndpoint,
  redirectUri: window.location.origin + '/session/login',
  useSilentRefresh: false,
  clientId: 'lukla_crediting_app_client',
  responseType: 'code',
  requireHttps: false, //TODO: fix and make https required
  scope: 'openid profile offline_access lukla_profile_api lukla_auditing_api lukla_crediting_api lukla_ingestion_api lukla_inventory_api lukla_task_api',
  showDebugInformation: true,
  sessionChecksEnabled: false
};