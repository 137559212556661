/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adjustAssets } from '../fn/reporting-periods/adjust-assets';
import { AdjustAssets$Params } from '../fn/reporting-periods/adjust-assets';
import { assignAsset } from '../fn/reporting-periods/assign-asset';
import { AssignAsset$Params } from '../fn/reporting-periods/assign-asset';
import { assignAssets } from '../fn/reporting-periods/assign-assets';
import { AssignAssets$Params } from '../fn/reporting-periods/assign-assets';
import { getPeriod } from '../fn/reporting-periods/get-period';
import { GetPeriod$Params } from '../fn/reporting-periods/get-period';
import { PeriodAssetItem as LuklaCreditingPeriodAssetItem } from '../models/Lukla/Crediting/period-asset-item';
import { PeriodItem as LuklaCreditingPeriodItem } from '../models/Lukla/Crediting/period-item';
import { periodAssets } from '../fn/reporting-periods/period-assets';
import { PeriodAssets$Params } from '../fn/reporting-periods/period-assets';
import { removeAsset } from '../fn/reporting-periods/remove-asset';
import { RemoveAsset$Params } from '../fn/reporting-periods/remove-asset';
import { removeReportingPeriod } from '../fn/reporting-periods/remove-reporting-period';
import { RemoveReportingPeriod$Params } from '../fn/reporting-periods/remove-reporting-period';
import { upsertReportingPeriod } from '../fn/reporting-periods/upsert-reporting-period';
import { UpsertReportingPeriod$Params } from '../fn/reporting-periods/upsert-reporting-period';

@Injectable({ providedIn: 'root' })
export class ReportingPeriodsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getPeriod()` */
  static readonly GetPeriodPath = '/periods/{periodId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPeriod()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeriod$Response(params: GetPeriod$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingPeriodItem>> {
    return getPeriod(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPeriod$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeriod(params: GetPeriod$Params, context?: HttpContext): Observable<LuklaCreditingPeriodItem> {
    return this.getPeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingPeriodItem>): LuklaCreditingPeriodItem => r.body)
    );
  }

  /** Path part for operation `removeReportingPeriod()` */
  static readonly RemoveReportingPeriodPath = '/periods/{periodId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeReportingPeriod()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeReportingPeriod$Response(params: RemoveReportingPeriod$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeReportingPeriod(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeReportingPeriod$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeReportingPeriod(params: RemoveReportingPeriod$Params, context?: HttpContext): Observable<void> {
    return this.removeReportingPeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `upsertReportingPeriod()` */
  static readonly UpsertReportingPeriodPath = '/periods/upsert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertReportingPeriod()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertReportingPeriod$Response(params?: UpsertReportingPeriod$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return upsertReportingPeriod(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertReportingPeriod$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertReportingPeriod(params?: UpsertReportingPeriod$Params, context?: HttpContext): Observable<string> {
    return this.upsertReportingPeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `periodAssets()` */
  static readonly PeriodAssetsPath = '/periods/{periodId}/assets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `periodAssets()` instead.
   *
   * This method doesn't expect any request body.
   */
  periodAssets$Response(params: PeriodAssets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingPeriodAssetItem>>> {
    return periodAssets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `periodAssets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  periodAssets(params: PeriodAssets$Params, context?: HttpContext): Observable<Array<LuklaCreditingPeriodAssetItem>> {
    return this.periodAssets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingPeriodAssetItem>>): Array<LuklaCreditingPeriodAssetItem> => r.body)
    );
  }

  /** Path part for operation `assignAsset()` */
  static readonly AssignAssetPath = '/periods/asset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignAsset()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignAsset$Response(params?: AssignAsset$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return assignAsset(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignAsset$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignAsset(params?: AssignAsset$Params, context?: HttpContext): Observable<void> {
    return this.assignAsset$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `removeAsset()` */
  static readonly RemoveAssetPath = '/periods/asset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeAsset()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeAsset$Response(params?: RemoveAsset$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return removeAsset(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeAsset$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  removeAsset(params?: RemoveAsset$Params, context?: HttpContext): Observable<void> {
    return this.removeAsset$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `assignAssets()` */
  static readonly AssignAssetsPath = '/periods/assets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignAssets()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignAssets$Response(params?: AssignAssets$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return assignAssets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignAssets$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assignAssets(params?: AssignAssets$Params, context?: HttpContext): Observable<void> {
    return this.assignAssets$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adjustAssets()` */
  static readonly AdjustAssetsPath = '/periods/assets/adjust';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adjustAssets()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adjustAssets$Response(params?: AdjustAssets$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adjustAssets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adjustAssets$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adjustAssets(params?: AdjustAssets$Params, context?: HttpContext): Observable<void> {
    return this.adjustAssets$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
