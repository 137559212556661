/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { active } from '../fn/incident/active';
import { Active$Params } from '../fn/incident/active';
import { assetIncidents } from '../fn/incident/asset-incidents';
import { AssetIncidents$Params } from '../fn/incident/asset-incidents';
import { company } from '../fn/incident/company';
import { Company$Params } from '../fn/incident/company';
import { details } from '../fn/incident/details';
import { Details$Params } from '../fn/incident/details';
import { Response as LuklaIngestionActiveIncidentsResponse } from '../models/Lukla/Ingestion/ActiveIncidents/response';
import { Response as LuklaIngestionAssetIncidentsResponse } from '../models/Lukla/Ingestion/AssetIncidents/response';
import { Response as LuklaIngestionCompanyIncidentsResponse } from '../models/Lukla/Ingestion/CompanyIncidents/response';

@Injectable({ providedIn: 'root' })
export class IncidentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `active()` */
  static readonly ActivePath = '/incidents/active';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `active()` instead.
   *
   * This method doesn't expect any request body.
   */
  active$Response(params?: Active$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaIngestionActiveIncidentsResponse>> {
    return active(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `active$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  active(params?: Active$Params, context?: HttpContext): Observable<LuklaIngestionActiveIncidentsResponse> {
    return this.active$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaIngestionActiveIncidentsResponse>): LuklaIngestionActiveIncidentsResponse => r.body)
    );
  }

  /** Path part for operation `company()` */
  static readonly CompanyPath = '/incidents/company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `company()` instead.
   *
   * This method doesn't expect any request body.
   */
  company$Response(params?: Company$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaIngestionCompanyIncidentsResponse>> {
    return company(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `company$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  company(params?: Company$Params, context?: HttpContext): Observable<LuklaIngestionCompanyIncidentsResponse> {
    return this.company$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaIngestionCompanyIncidentsResponse>): LuklaIngestionCompanyIncidentsResponse => r.body)
    );
  }

  /** Path part for operation `details()` */
  static readonly DetailsPath = '/incidents/active/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `details()` instead.
   *
   * This method doesn't expect any request body.
   */
  details$Response(params: Details$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaIngestionActiveIncidentsResponse>> {
    return details(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `details$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  details(params: Details$Params, context?: HttpContext): Observable<LuklaIngestionActiveIncidentsResponse> {
    return this.details$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaIngestionActiveIncidentsResponse>): LuklaIngestionActiveIncidentsResponse => r.body)
    );
  }

  /** Path part for operation `assetIncidents()` */
  static readonly AssetIncidentsPath = '/incidents/incidents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assetIncidents()` instead.
   *
   * This method doesn't expect any request body.
   */
  assetIncidents$Response(params?: AssetIncidents$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaIngestionAssetIncidentsResponse>> {
    return assetIncidents(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assetIncidents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assetIncidents(params?: AssetIncidents$Params, context?: HttpContext): Observable<LuklaIngestionAssetIncidentsResponse> {
    return this.assetIncidents$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaIngestionAssetIncidentsResponse>): LuklaIngestionAssetIncidentsResponse => r.body)
    );
  }

}
