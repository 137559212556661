import { Injectable, inject } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { lastValueFrom } from 'rxjs';
import { UserTypeEnum } from 'src/app/api/models/Lukla/Services/user-type-enum';
import { ProfileService } from 'src/app/api/services/profile.service';
import { ValidationAccessService } from 'src/app/api/services/validation-access.service';

@Injectable()
export class ValidatorPermissionService {
  constructor(private router: Router, private oauthService: OAuthService, private accessService: ValidationAccessService, private profileService: ProfileService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var accessToken = this.oauthService.getAccessToken();
    var user = await lastValueFrom(this.profileService.current());
    if (user.userType === UserTypeEnum.Lukla && accessToken) {
        return true;
    } else if (user.userType === UserTypeEnum.External && accessToken) {
      var validationId = route.params['id'];
      var hasAccess = await this.accessService.confirmValidatorAccess({validationId: validationId}).toPromise();
      if (hasAccess) {
        return true;
      }
      this.router.navigate(['/no-access']);
      return false; 
    } else {
      this.router.navigate(['/no-access']);
      return false;
    }
  }
}

export const ValidatorAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> =>
    inject(ValidatorPermissionService).canActivate(next, state);
