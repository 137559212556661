/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addDataNotes } from '../fn/asset-data/add-data-notes';
import { AddDataNotes$Params } from '../fn/asset-data/add-data-notes';
import { bulkGetVariableData } from '../fn/asset-data/bulk-get-variable-data';
import { BulkGetVariableData$Params } from '../fn/asset-data/bulk-get-variable-data';
import { executeBulkOverride } from '../fn/asset-data/execute-bulk-override';
import { ExecuteBulkOverride$Params } from '../fn/asset-data/execute-bulk-override';
import { getVariableData } from '../fn/asset-data/get-variable-data';
import { GetVariableData$Params } from '../fn/asset-data/get-variable-data';
import { AssetDataView as LuklaCreditingAssetDataView } from '../models/Lukla/Crediting/asset-data-view';
import { OverrideEntryView as LuklaCreditingGetOverrideEntryOverrideEntryView } from '../models/Lukla/Crediting/GetOverrideEntry/override-entry-view';
import { Response as LuklaCreditingValidateBulkOverridePackageResponse } from '../models/Lukla/Crediting/ValidateBulkOverridePackage/response';
import { overrideData } from '../fn/asset-data/override-data';
import { OverrideData$Params } from '../fn/asset-data/override-data';
import { overrideDetails } from '../fn/asset-data/override-details';
import { OverrideDetails$Params } from '../fn/asset-data/override-details';
import { validateBulkOverride } from '../fn/asset-data/validate-bulk-override';
import { ValidateBulkOverride$Params } from '../fn/asset-data/validate-bulk-override';

@Injectable({ providedIn: 'root' })
export class AssetDataService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getVariableData()` */
  static readonly GetVariableDataPath = '/asset/data/variable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVariableData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVariableData$Response(params?: GetVariableData$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingAssetDataView>>> {
    return getVariableData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVariableData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getVariableData(params?: GetVariableData$Params, context?: HttpContext): Observable<Array<LuklaCreditingAssetDataView>> {
    return this.getVariableData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingAssetDataView>>): Array<LuklaCreditingAssetDataView> => r.body)
    );
  }

  /** Path part for operation `bulkGetVariableData()` */
  static readonly BulkGetVariableDataPath = '/asset/data/bulk/variable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkGetVariableData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bulkGetVariableData$Response(params?: BulkGetVariableData$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingAssetDataView>>> {
    return bulkGetVariableData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkGetVariableData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bulkGetVariableData(params?: BulkGetVariableData$Params, context?: HttpContext): Observable<Array<LuklaCreditingAssetDataView>> {
    return this.bulkGetVariableData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingAssetDataView>>): Array<LuklaCreditingAssetDataView> => r.body)
    );
  }

  /** Path part for operation `addDataNotes()` */
  static readonly AddDataNotesPath = '/asset/data/notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addDataNotes()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addDataNotes$Response(params?: AddDataNotes$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addDataNotes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addDataNotes$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addDataNotes(params?: AddDataNotes$Params, context?: HttpContext): Observable<void> {
    return this.addDataNotes$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `overrideDetails()` */
  static readonly OverrideDetailsPath = '/asset/data/override/details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `overrideDetails()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  overrideDetails$Response(params?: OverrideDetails$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingGetOverrideEntryOverrideEntryView>> {
    return overrideDetails(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `overrideDetails$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  overrideDetails(params?: OverrideDetails$Params, context?: HttpContext): Observable<LuklaCreditingGetOverrideEntryOverrideEntryView> {
    return this.overrideDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingGetOverrideEntryOverrideEntryView>): LuklaCreditingGetOverrideEntryOverrideEntryView => r.body)
    );
  }

  /** Path part for operation `overrideData()` */
  static readonly OverrideDataPath = '/asset/data/override';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `overrideData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  overrideData$Response(params?: OverrideData$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return overrideData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `overrideData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  overrideData(params?: OverrideData$Params, context?: HttpContext): Observable<void> {
    return this.overrideData$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `validateBulkOverride()` */
  static readonly ValidateBulkOverridePath = '/asset/data/override/import/validate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validateBulkOverride()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateBulkOverride$Response(params?: ValidateBulkOverride$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingValidateBulkOverridePackageResponse>> {
    return validateBulkOverride(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validateBulkOverride$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  validateBulkOverride(params?: ValidateBulkOverride$Params, context?: HttpContext): Observable<LuklaCreditingValidateBulkOverridePackageResponse> {
    return this.validateBulkOverride$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingValidateBulkOverridePackageResponse>): LuklaCreditingValidateBulkOverridePackageResponse => r.body)
    );
  }

  /** Path part for operation `executeBulkOverride()` */
  static readonly ExecuteBulkOverridePath = '/asset/data/override/import/execute';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `executeBulkOverride()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  executeBulkOverride$Response(params?: ExecuteBulkOverride$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return executeBulkOverride(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `executeBulkOverride$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  executeBulkOverride(params?: ExecuteBulkOverride$Params, context?: HttpContext): Observable<void> {
    return this.executeBulkOverride$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
