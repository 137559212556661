import { Injectable, inject } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthPermissionService {
  constructor(private router: Router, private oauthService: OAuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var accessToken = this.oauthService.getAccessToken();
    if (accessToken) {
      return true;
    } else {
      this.router.navigate(['/session/end'], { queryParams: { returnUrl: state.url }});
      return false;
    }
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean =>
    inject(AuthPermissionService).canActivate(next, state);
