import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faArrowDown, faArrowUp, faBars, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { TaskActionEnum } from '../../../utils/task-actions-enum.model';
import { TaskTemplateStep } from '../../../../api/models/Lukla/Inventory/task-template-step';

@Component({
  selector: 'task-define-step',
  templateUrl: './define-step.component.html',
  styleUrls: ['./define-step.component.scss']
})
export class DefineStepComponent implements OnInit {

  constructor() { }

  @Input() step : TaskTemplateStep;
  @Input() isEditable = true;
  @Output() stepChange = new EventEmitter<TaskTemplateStep>();
  @Output() stepDeleted = new EventEmitter<TaskTemplateStep>();
  @Output() moveStepUp = new EventEmitter<TaskTemplateStep>();
  @Output() moveStepDown = new EventEmitter<TaskTemplateStep>();

  //  FLAGS
  confirmDelete = false;

  //  ICONS
  deleteIcon = faTimes;
  dragIcon = faBars;
  upIcon = faArrowUp;
  downIcon = faArrowDown;

  action = TaskActionEnum;

  ngOnInit(): void {}


  confirmingDelete = () => this.confirmDelete = true;
  deleteStep = () => this.stepDeleted.emit(this.step);
  moveup = () => this.moveStepUp.emit(this.step);
  movedown = () => this.moveStepDown.emit(this.step);
  toggleRequired = () => {
    this.step.isRequired = !this.step.isRequired;
    this.stepChange.emit(this.step);
  }

  isInventory = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.Inventory
  isGeolocation = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.Geolocate;
  isAssignTag = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.AssignTag;
  isCheck = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.Check;
  isVerify = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.Verify;
  isPhoto = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.Photo;
  isFile = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.File;
  isComment = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.Comment;
  isEnd = (step: TaskTemplateStep) => step.actionType === <any>TaskActionEnum.End;
}
