import { Route } from '@angular/router';
import { ErrorComponent } from './pages/redirects/error/error.component';
import { NoAccessComponent } from './pages/redirects/no-access/no-access.component';
import { UnauthorizedComponent } from './pages/redirects/unauthorized/unauthorized.component';
import { UnknownComponent } from './pages/redirects/unknown/unknown.component';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    loadChildren: () => import('./pages/dashboard/dashboard.routes').then((mod) => mod.DASHBOARD_ROUTES),
  },
  {
    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.routes').then((mod) => mod.ADMIN_ROUTES),
  },
  {
    path: 'projects',
    loadChildren: () => import('./pages/project/project.routes').then((mod) => mod.PROJECT_ROUTES),
  },
  {
    path: 'session',
    loadChildren: () => import('./pages/auth/auth.routes').then((mod) => mod.AUTH_ROUTES),
  },
  {
    path: 'technician',
    loadChildren: () => import('./pages/external/technician/technician-routing').then((mod) => mod.TECHNICIAN_ROUTES),
  },
  {
    path: 'validator',
    loadChildren: () => import('./pages/external/validator/validator-routing').then((mod) => mod.VALIDATOR_ROUTES),
  },
  {
    path: 'verification',
    loadChildren: () => import('./pages/verification/verification.routes').then((mod) => mod.VERIFICATION_ROUTES),
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'no-access', component: NoAccessComponent },
  { path: '**', component: UnknownComponent },
];
