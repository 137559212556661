import { HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { ApiModule } from './api/api.module';
import { SharedModule } from './shared/shared.module';
import { provideNgxMask } from 'ngx-mask';
import { MathjaxModule } from 'mathjax-angular';
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { OttoTasksCoreModule } from 'projects/ottomate-tasks';
import { provideRouter } from '@angular/router';
import { APP_ROUTES } from './app.routes';

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    provideNgxMask(), provideRouter(APP_ROUTES),
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      SharedModule.forRoot(),
      TypeaheadModule.forRoot(),
      OAuthModule.forRoot({
        resourceServer: {
          allowedUrls: [
            environment.api,
            environment.ottotasksUrl
          ],
          sendAccessToken: true
        }
      }),
      OttoTasksCoreModule.forRoot({ apiRootUrl: environment.ottotasksUrl, tinymcekey: environment.tinymcekey }),
      ApiModule.forRoot({ rootUrl: environment.api }),
      MathjaxModule.forRoot(
        {
          "config": {
            "loader": {
              "load": ["input/asciimath", "output/chtml"]
            },
            "asciimath": {
              "delimiters": [['$', '$'], ['`', '`']]
            },
            "svg": { "fontCache": "global" }
          },
          "src": "https://cdn.jsdelivr.net/npm/mathjax@3.0.0/es5/startup.js"
        }
      )
    )
  ]
};
