/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AggregationDataView as LuklaCreditingAggregationDataView } from '../../models/Lukla/Crediting/aggregation-data-view';
import { Query as LuklaCreditingGetPeriodDataQuery } from '../../models/Lukla/Crediting/GetPeriodData/query';

export interface PeriodData$Params {
      body?: LuklaCreditingGetPeriodDataQuery
}

export function periodData(http: HttpClient, rootUrl: string, params?: PeriodData$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingAggregationDataView>>> {
  const rb = new RequestBuilder(rootUrl, periodData.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<LuklaCreditingAggregationDataView>>;
    })
  );
}

periodData.PATH = '/crediting/data/period';
