<div class="row">
    <div id="landing-page" class="g-0 col-xs-12">
        <div id="landing-container">
            <div class="header">
                <img src="https://app.lukla.co/Content/img/Login/logo-white.png">
                <div class="d-flex justify-content-end align-items-baseline workspace-title">
                    <h3 class="display-2">Crediting</h3>
                </div>
                <div class="alert alert-warning landing-item">
                    <div class="d-flex align-items-center">
                        <h3>
                            <fa-icon [icon]="noAccessIcon"></fa-icon> Unauthorized
                        </h3>
                    </div>
                    <p>You are Unauthorized to view this page, if you believe this is in error please contact our <a
                            href="mailto:support@lukla.co">support team</a></p>
                </div>
            </div>
        </div>
    </div>
</div>