import { Component } from '@angular/core';
import { faMapSigns } from '@fortawesome/pro-solid-svg-icons';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'lukla-unknown',
  standalone: true,
  templateUrl: './unknown.component.html',
  styleUrls: ['./unknown.component.scss'],
  imports: [SharedModule]
})
export class UnknownComponent {
  notFoundIcon = faMapSigns;
}
