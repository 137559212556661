import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserTypeEnum } from 'src/app/api/models/Lukla/Services/user-type-enum';
import { CurrentUserService } from '../../lukla/user.service';

@Injectable()
export class LuklaPermissionService {
    constructor(
        private router: Router,
        private userService: CurrentUserService) { }

    async canActivate() {
        var user = await this.userService.get();
        if (user.userType === UserTypeEnum.Lukla) {
            return true;
        }
        this.router.navigate(['/unauthorized']);
        return false;
    }
}

export const LuklaUserGuard: CanActivateFn = (): Promise<boolean> =>
    inject(LuklaPermissionService).canActivate();