/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { GroupTimeline as LuklaCreditingGroupTimeline } from '../../models/Lukla/Crediting/group-timeline';
import { Query as LuklaCreditingProjectTimelineQuery } from '../../models/Lukla/Crediting/ProjectTimeline/query';

export interface ProjectTimeline$Params {
      body?: LuklaCreditingProjectTimelineQuery
}

export function projectTimeline(http: HttpClient, rootUrl: string, params?: ProjectTimeline$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingGroupTimeline>> {
  const rb = new RequestBuilder(rootUrl, projectTimeline.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<LuklaCreditingGroupTimeline>;
    })
  );
}

projectTimeline.PATH = '/analysis/project/timeline';
