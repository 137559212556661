import { NgModule } from '@angular/core';
import { UserComponent } from './user/user.component';
import { HeaderProfileComponent } from './header-profile/header-profile.component';
import { CommonModule } from '@angular/common';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PersonPickerComponent } from './person-picker/person-picker.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
@NgModule({
  declarations: [
    UserComponent,
    PersonPickerComponent,
    HeaderProfileComponent,
    ConfirmationModalComponent
  ],
  imports: [
    CommonModule,
    PopoverModule,
    FontAwesomeModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    UserComponent,
    PersonPickerComponent,
    HeaderProfileComponent,
    ConfirmationModalComponent
  ]
})
export class LuklaModule { }
