/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TaskQuery as LuklaTasksGetOutstandingTasksTaskQuery } from '../../models/Lukla/Tasks/GetOutstandingTasks/task-query';
import { TaskTaskListItem as LuklaTasksTaskTaskListItem } from '../../models/Lukla/Tasks/task-task-list-item';

export interface Outstanding$Params {
      body?: LuklaTasksGetOutstandingTasksTaskQuery
}

export function outstanding(http: HttpClient, rootUrl: string, params?: Outstanding$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaTasksTaskTaskListItem>>> {
  const rb = new RequestBuilder(rootUrl, outstanding.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<LuklaTasksTaskTaskListItem>>;
    })
  );
}

outstanding.PATH = '/tasks/queries/outstanding';
