/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Query as LuklaCreditingAssetCumulativeQuery } from '../../models/Lukla/Crediting/AssetCumulative/query';
import { GroupCumulativeItem as LuklaCreditingGroupCumulativeItem } from '../../models/Lukla/Crediting/group-cumulative-item';

export interface AssetCumulative$Params {
      body?: LuklaCreditingAssetCumulativeQuery
}

export function assetCumulative(http: HttpClient, rootUrl: string, params?: AssetCumulative$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingGroupCumulativeItem>> {
  const rb = new RequestBuilder(rootUrl, assetCumulative.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/*+json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<LuklaCreditingGroupCumulativeItem>;
    })
  );
}

assetCumulative.PATH = '/analysis/asset/cumulative';
