/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Response as LuklaCreditingValidateBulkOverridePackageResponse } from '../../models/Lukla/Crediting/ValidateBulkOverridePackage/response';

export interface ValidateBulkOverride$Params {
      body?: {
'file'?: Blob;
'AssetId'?: string;
}
}

export function validateBulkOverride(http: HttpClient, rootUrl: string, params?: ValidateBulkOverride$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingValidateBulkOverridePackageResponse>> {
  const rb = new RequestBuilder(rootUrl, validateBulkOverride.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<LuklaCreditingValidateBulkOverridePackageResponse>;
    })
  );
}

validateBulkOverride.PATH = '/asset/data/override/import/validate';
