/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { confirmTechnicianAccess } from '../fn/technician-access/confirm-technician-access';
import { ConfirmTechnicianAccess$Params } from '../fn/technician-access/confirm-technician-access';
import { getTechnicians } from '../fn/technician-access/get-technicians';
import { GetTechnicians$Params } from '../fn/technician-access/get-technicians';
import { TechnicianView as LuklaCreditingTechnicianView } from '../models/Lukla/Crediting/technician-view';
import { registerTechnician } from '../fn/technician-access/register-technician';
import { RegisterTechnician$Params } from '../fn/technician-access/register-technician';
import { revokeTechnician } from '../fn/technician-access/revoke-technician';
import { RevokeTechnician$Params } from '../fn/technician-access/revoke-technician';

@Injectable({ providedIn: 'root' })
export class TechnicianAccessService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTechnicians()` */
  static readonly GetTechniciansPath = '/access/technician/{assetDataMapId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTechnicians()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTechnicians$Response(params: GetTechnicians$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingTechnicianView>>> {
    return getTechnicians(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTechnicians$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTechnicians(params: GetTechnicians$Params, context?: HttpContext): Observable<Array<LuklaCreditingTechnicianView>> {
    return this.getTechnicians$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingTechnicianView>>): Array<LuklaCreditingTechnicianView> => r.body)
    );
  }

  /** Path part for operation `registerTechnician()` */
  static readonly RegisterTechnicianPath = '/access/technician/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerTechnician()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerTechnician$Response(params?: RegisterTechnician$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return registerTechnician(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerTechnician$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerTechnician(params?: RegisterTechnician$Params, context?: HttpContext): Observable<string> {
    return this.registerTechnician$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `revokeTechnician()` */
  static readonly RevokeTechnicianPath = '/access/technician/revoke';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeTechnician()` instead.
   *
   * This method doesn't expect any request body.
   */
  revokeTechnician$Response(params?: RevokeTechnician$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return revokeTechnician(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `revokeTechnician$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  revokeTechnician(params?: RevokeTechnician$Params, context?: HttpContext): Observable<void> {
    return this.revokeTechnician$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `confirmTechnicianAccess()` */
  static readonly ConfirmTechnicianAccessPath = '/access/technician/confirm/{assetDataMapId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmTechnicianAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirmTechnicianAccess$Response(params: ConfirmTechnicianAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return confirmTechnicianAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `confirmTechnicianAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirmTechnicianAccess(params: ConfirmTechnicianAccess$Params, context?: HttpContext): Observable<boolean> {
    return this.confirmTechnicianAccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
