/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { cloneMethodology } from '../fn/methodology/clone-methodology';
import { CloneMethodology$Params } from '../fn/methodology/clone-methodology';
import { getLatestMethodologyTemplate } from '../fn/methodology/get-latest-methodology-template';
import { GetLatestMethodologyTemplate$Params } from '../fn/methodology/get-latest-methodology-template';
import { getMethodologies } from '../fn/methodology/get-methodologies';
import { GetMethodologies$Params } from '../fn/methodology/get-methodologies';
import { getMethodology } from '../fn/methodology/get-methodology';
import { GetMethodology$Params } from '../fn/methodology/get-methodology';
import { getMethodologyTemplate } from '../fn/methodology/get-methodology-template';
import { GetMethodologyTemplate$Params } from '../fn/methodology/get-methodology-template';
import { MethodologyTemplate as LuklaCreditingMethodologyTemplate } from '../models/Lukla/Crediting/methodology-template';
import { MethodologyTemplateView as LuklaCreditingMethodologyTemplateView } from '../models/Lukla/Crediting/methodology-template-view';
import { MethodologyView as LuklaCreditingMethodologyView } from '../models/Lukla/Crediting/methodology-view';
import { upsertMethodology } from '../fn/methodology/upsert-methodology';
import { UpsertMethodology$Params } from '../fn/methodology/upsert-methodology';

@Injectable({ providedIn: 'root' })
export class MethodologyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getMethodology()` */
  static readonly GetMethodologyPath = '/methodology/{methodologyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMethodology()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMethodology$Response(params: GetMethodology$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingMethodologyView>> {
    return getMethodology(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMethodology$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMethodology(params: GetMethodology$Params, context?: HttpContext): Observable<LuklaCreditingMethodologyView> {
    return this.getMethodology$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingMethodologyView>): LuklaCreditingMethodologyView => r.body)
    );
  }

  /** Path part for operation `getMethodologies()` */
  static readonly GetMethodologiesPath = '/methodology/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMethodologies()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMethodologies$Response(params?: GetMethodologies$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingMethodologyView>>> {
    return getMethodologies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMethodologies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMethodologies(params?: GetMethodologies$Params, context?: HttpContext): Observable<Array<LuklaCreditingMethodologyView>> {
    return this.getMethodologies$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingMethodologyView>>): Array<LuklaCreditingMethodologyView> => r.body)
    );
  }

  /** Path part for operation `cloneMethodology()` */
  static readonly CloneMethodologyPath = '/methodology/clone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cloneMethodology()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cloneMethodology$Response(params?: CloneMethodology$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return cloneMethodology(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cloneMethodology$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cloneMethodology(params?: CloneMethodology$Params, context?: HttpContext): Observable<void> {
    return this.cloneMethodology$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getMethodologyTemplate()` */
  static readonly GetMethodologyTemplatePath = '/methodology/template/{methodologyTemplateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMethodologyTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMethodologyTemplate$Response(params: GetMethodologyTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingMethodologyTemplate>> {
    return getMethodologyTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMethodologyTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMethodologyTemplate(params: GetMethodologyTemplate$Params, context?: HttpContext): Observable<LuklaCreditingMethodologyTemplate> {
    return this.getMethodologyTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingMethodologyTemplate>): LuklaCreditingMethodologyTemplate => r.body)
    );
  }

  /** Path part for operation `getLatestMethodologyTemplate()` */
  static readonly GetLatestMethodologyTemplatePath = '/methodology/{methodologyId}/latest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestMethodologyTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestMethodologyTemplate$Response(params: GetLatestMethodologyTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingMethodologyTemplateView>> {
    return getLatestMethodologyTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestMethodologyTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestMethodologyTemplate(params: GetLatestMethodologyTemplate$Params, context?: HttpContext): Observable<LuklaCreditingMethodologyTemplateView> {
    return this.getLatestMethodologyTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingMethodologyTemplateView>): LuklaCreditingMethodologyTemplateView => r.body)
    );
  }

  /** Path part for operation `upsertMethodology()` */
  static readonly UpsertMethodologyPath = '/methodology/upsert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertMethodology()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertMethodology$Response(params?: UpsertMethodology$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return upsertMethodology(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertMethodology$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertMethodology(params?: UpsertMethodology$Params, context?: HttpContext): Observable<string> {
    return this.upsertMethodology$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
