/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addExpectation } from '../fn/expectation/add-expectation';
import { AddExpectation$Params } from '../fn/expectation/add-expectation';
import { getAssetExpectations } from '../fn/expectation/get-asset-expectations';
import { GetAssetExpectations$Params } from '../fn/expectation/get-asset-expectations';
import { getProjectExpectationsByFeed } from '../fn/expectation/get-project-expectations-by-feed';
import { GetProjectExpectationsByFeed$Params } from '../fn/expectation/get-project-expectations-by-feed';
import { ExpectationItem as LuklaCreditingExpectationItem } from '../models/Lukla/Crediting/expectation-item';
import { GroupedExpectationItem as LuklaCreditingGroupedExpectationItem } from '../models/Lukla/Crediting/grouped-expectation-item';

@Injectable({ providedIn: 'root' })
export class ExpectationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getProjectExpectationsByFeed()` */
  static readonly GetProjectExpectationsByFeedPath = '/expectations/project/{projectId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProjectExpectationsByFeed()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectExpectationsByFeed$Response(params: GetProjectExpectationsByFeed$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingGroupedExpectationItem>>> {
    return getProjectExpectationsByFeed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProjectExpectationsByFeed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProjectExpectationsByFeed(params: GetProjectExpectationsByFeed$Params, context?: HttpContext): Observable<Array<LuklaCreditingGroupedExpectationItem>> {
    return this.getProjectExpectationsByFeed$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingGroupedExpectationItem>>): Array<LuklaCreditingGroupedExpectationItem> => r.body)
    );
  }

  /** Path part for operation `getAssetExpectations()` */
  static readonly GetAssetExpectationsPath = '/expectations/asset/{assetId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAssetExpectations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssetExpectations$Response(params: GetAssetExpectations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingExpectationItem>>> {
    return getAssetExpectations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAssetExpectations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAssetExpectations(params: GetAssetExpectations$Params, context?: HttpContext): Observable<Array<LuklaCreditingExpectationItem>> {
    return this.getAssetExpectations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingExpectationItem>>): Array<LuklaCreditingExpectationItem> => r.body)
    );
  }

  /** Path part for operation `addExpectation()` */
  static readonly AddExpectationPath = '/expectations/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addExpectation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addExpectation$Response(params?: AddExpectation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addExpectation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addExpectation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addExpectation(params?: AddExpectation$Params, context?: HttpContext): Observable<void> {
    return this.addExpectation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
