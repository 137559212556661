import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService } from 'src/app/lukla/user.service';
import { ProfileView } from 'src/app/api/models/Lukla/Profile/profile-view';
import { IncidentService } from 'src/app/api/services/incident.service';
import { faBoxes, faExclamation, faRectangleVerticalHistory, faSignOutAlt, faTachometerAlt, faUserTie } from '@fortawesome/pro-duotone-svg-icons';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'crediting-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {

  constructor(
    private router: Router,
    private currentUserService: CurrentUserService,
    private incidentService: IncidentService
  ) { }

  @Input() noInteraction: boolean = false;
  @Input() showLogin: boolean = true;

  //  Icons
  logoutIcon = faSignOutAlt;
  assetIcon = faBoxes;
  incidentIcon = faExclamation;
  dashboardIcon = faTachometerAlt;
  projectIcon = faRectangleVerticalHistory;
  adminIcon = faUserTie;

  activeIncidents = 0;
  hasActiveIncidents = false;
  isAdmin = false;
  currentUser: ProfileView;
  nameForAsset: {
    singular: string;
    plural: string
  }

  async ngOnInit(): Promise<void> {
    this.nameForAsset = {
      singular: environment?.nameForAsset.singular,
      plural: environment?.nameForAsset.plural
    }
    this.currentUser = await this.currentUserService.get();
    this.isAdmin = this.currentUserService.isAdmin();

    if (!this.noInteraction) {
      this.load();
    }
  }

  async load() {
    const incidents = await this.incidentService.active().toPromise();
    this.activeIncidents = incidents.items.length;
    this.hasActiveIncidents = incidents.items.length > 0;
  }

  logout() {
    this.router.navigate(['session', 'end']);
  }
}
