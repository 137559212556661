/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { assetCumulative } from '../fn/analysis/asset-cumulative';
import { AssetCumulative$Params } from '../fn/analysis/asset-cumulative';
import { assetsAssertions } from '../fn/analysis/assets-assertions';
import { AssetsAssertions$Params } from '../fn/analysis/assets-assertions';
import { assetsTimeline } from '../fn/analysis/assets-timeline';
import { AssetsTimeline$Params } from '../fn/analysis/assets-timeline';
import { assetTimeline } from '../fn/analysis/asset-timeline';
import { AssetTimeline$Params } from '../fn/analysis/asset-timeline';
import { AssertionItem as LuklaCreditingAssertionItem } from '../models/Lukla/Crediting/assertion-item';
import { CalculationTimeline as LuklaCreditingCalculationTimeline } from '../models/Lukla/Crediting/calculation-timeline';
import { GroupCumulativeItem as LuklaCreditingGroupCumulativeItem } from '../models/Lukla/Crediting/group-cumulative-item';
import { GroupTimeline as LuklaCreditingGroupTimeline } from '../models/Lukla/Crediting/group-timeline';
import { SingleTimelineItem as LuklaCreditingSingleTimelineItem } from '../models/Lukla/Crediting/single-timeline-item';
import { periodCumulative } from '../fn/analysis/period-cumulative';
import { PeriodCumulative$Params } from '../fn/analysis/period-cumulative';
import { periodTimeline } from '../fn/analysis/period-timeline';
import { PeriodTimeline$Params } from '../fn/analysis/period-timeline';
import { projectCumulative } from '../fn/analysis/project-cumulative';
import { ProjectCumulative$Params } from '../fn/analysis/project-cumulative';
import { projectTimeline } from '../fn/analysis/project-timeline';
import { ProjectTimeline$Params } from '../fn/analysis/project-timeline';
import { verificationTimeline } from '../fn/analysis/verification-timeline';
import { VerificationTimeline$Params } from '../fn/analysis/verification-timeline';

@Injectable({ providedIn: 'root' })
export class AnalysisService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `projectTimeline()` */
  static readonly ProjectTimelinePath = '/analysis/project/timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectTimeline()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  projectTimeline$Response(params?: ProjectTimeline$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingGroupTimeline>> {
    return projectTimeline(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectTimeline$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  projectTimeline(params?: ProjectTimeline$Params, context?: HttpContext): Observable<LuklaCreditingGroupTimeline> {
    return this.projectTimeline$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingGroupTimeline>): LuklaCreditingGroupTimeline => r.body)
    );
  }

  /** Path part for operation `projectCumulative()` */
  static readonly ProjectCumulativePath = '/analysis/project/cumulative';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `projectCumulative()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  projectCumulative$Response(params?: ProjectCumulative$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingGroupCumulativeItem>> {
    return projectCumulative(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `projectCumulative$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  projectCumulative(params?: ProjectCumulative$Params, context?: HttpContext): Observable<LuklaCreditingGroupCumulativeItem> {
    return this.projectCumulative$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingGroupCumulativeItem>): LuklaCreditingGroupCumulativeItem => r.body)
    );
  }

  /** Path part for operation `periodCumulative()` */
  static readonly PeriodCumulativePath = '/analysis/period/cumulative';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `periodCumulative()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  periodCumulative$Response(params?: PeriodCumulative$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingCalculationTimeline>>> {
    return periodCumulative(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `periodCumulative$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  periodCumulative(params?: PeriodCumulative$Params, context?: HttpContext): Observable<Array<LuklaCreditingCalculationTimeline>> {
    return this.periodCumulative$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingCalculationTimeline>>): Array<LuklaCreditingCalculationTimeline> => r.body)
    );
  }

  /** Path part for operation `periodTimeline()` */
  static readonly PeriodTimelinePath = '/analysis/period/timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `periodTimeline()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  periodTimeline$Response(params?: PeriodTimeline$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingGroupTimeline>> {
    return periodTimeline(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `periodTimeline$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  periodTimeline(params?: PeriodTimeline$Params, context?: HttpContext): Observable<LuklaCreditingGroupTimeline> {
    return this.periodTimeline$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingGroupTimeline>): LuklaCreditingGroupTimeline => r.body)
    );
  }

  /** Path part for operation `verificationTimeline()` */
  static readonly VerificationTimelinePath = '/analysis/verification/timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verificationTimeline()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  verificationTimeline$Response(params?: VerificationTimeline$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingGroupTimeline>> {
    return verificationTimeline(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `verificationTimeline$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  verificationTimeline(params?: VerificationTimeline$Params, context?: HttpContext): Observable<LuklaCreditingGroupTimeline> {
    return this.verificationTimeline$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingGroupTimeline>): LuklaCreditingGroupTimeline => r.body)
    );
  }

  /** Path part for operation `assetsTimeline()` */
  static readonly AssetsTimelinePath = '/analysis/assets/timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assetsTimeline()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assetsTimeline$Response(params?: AssetsTimeline$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingGroupTimeline>> {
    return assetsTimeline(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assetsTimeline$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assetsTimeline(params?: AssetsTimeline$Params, context?: HttpContext): Observable<LuklaCreditingGroupTimeline> {
    return this.assetsTimeline$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingGroupTimeline>): LuklaCreditingGroupTimeline => r.body)
    );
  }

  /** Path part for operation `assetsAssertions()` */
  static readonly AssetsAssertionsPath = '/analysis/assets/assertions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assetsAssertions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assetsAssertions$Response(params?: AssetsAssertions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingAssertionItem>>> {
    return assetsAssertions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assetsAssertions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assetsAssertions(params?: AssetsAssertions$Params, context?: HttpContext): Observable<Array<LuklaCreditingAssertionItem>> {
    return this.assetsAssertions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingAssertionItem>>): Array<LuklaCreditingAssertionItem> => r.body)
    );
  }

  /** Path part for operation `assetCumulative()` */
  static readonly AssetCumulativePath = '/analysis/asset/cumulative';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assetCumulative()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assetCumulative$Response(params?: AssetCumulative$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingGroupCumulativeItem>> {
    return assetCumulative(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assetCumulative$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assetCumulative(params?: AssetCumulative$Params, context?: HttpContext): Observable<LuklaCreditingGroupCumulativeItem> {
    return this.assetCumulative$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingGroupCumulativeItem>): LuklaCreditingGroupCumulativeItem => r.body)
    );
  }

  /** Path part for operation `assetTimeline()` */
  static readonly AssetTimelinePath = '/analysis/asset/timeline';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assetTimeline()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assetTimeline$Response(params?: AssetTimeline$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingSingleTimelineItem>> {
    return assetTimeline(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assetTimeline$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assetTimeline(params?: AssetTimeline$Params, context?: HttpContext): Observable<LuklaCreditingSingleTimelineItem> {
    return this.assetTimeline$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingSingleTimelineItem>): LuklaCreditingSingleTimelineItem => r.body)
    );
  }

}
