import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'nonZeroDigit', standalone: true})
export class NonZeroPipe implements PipeTransform {
  transform(value: number): number {
    try {
        var valueNum = Number(value);

        //  Check if num has decimals
        if (!(!!(valueNum % 1))) return value;

        //  Check if num is less than 1
        if (value > 1) return +valueNum.toFixed(2);
        return +value.toFixed(1-Math.floor(Math.log(valueNum)/Math.log(10)));
    } catch(error) {
        return value;
    }
  }
}