import { Component } from '@angular/core';
import { faBomb } from '@fortawesome/pro-solid-svg-icons';
import { SharedModule } from '../../../shared/shared.module';

@Component({
  selector: 'lukla-app-error',
  standalone: true,
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  imports: [SharedModule]
})
export class ErrorComponent {
  errorIcon = faBomb;
}
