/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { assetDataMap } from '../fn/data-source/asset-data-map';
import { AssetDataMap$Params } from '../fn/data-source/asset-data-map';
import { assetMaps } from '../fn/data-source/asset-maps';
import { AssetMaps$Params } from '../fn/data-source/asset-maps';
import { attachDataFeed } from '../fn/data-source/attach-data-feed';
import { AttachDataFeed$Params } from '../fn/data-source/attach-data-feed';
import { attachInventoryItem } from '../fn/data-source/attach-inventory-item';
import { AttachInventoryItem$Params } from '../fn/data-source/attach-inventory-item';
import { createDemoFeed } from '../fn/data-source/create-demo-feed';
import { CreateDemoFeed$Params } from '../fn/data-source/create-demo-feed';
import { createTaskFeed } from '../fn/data-source/create-task-feed';
import { CreateTaskFeed$Params } from '../fn/data-source/create-task-feed';
import { findDatasource } from '../fn/data-source/find-datasource';
import { FindDatasource$Params } from '../fn/data-source/find-datasource';
import { getDatasources } from '../fn/data-source/get-datasources';
import { GetDatasources$Params } from '../fn/data-source/get-datasources';
import { AssetDataMapGridView as LuklaCreditingAssetDataMapGridView } from '../models/Lukla/Crediting/asset-data-map-grid-view';
import { AssetDataMapView as LuklaCreditingAssetDataMapView } from '../models/Lukla/Crediting/asset-data-map-view';
import { DataSourceView as LuklaCreditingDataSourceView } from '../models/Lukla/Crediting/data-source-view';
import { updateDatasource } from '../fn/data-source/update-datasource';
import { UpdateDatasource$Params } from '../fn/data-source/update-datasource';
import { upsertDatasource } from '../fn/data-source/upsert-datasource';
import { UpsertDatasource$Params } from '../fn/data-source/upsert-datasource';

@Injectable({ providedIn: 'root' })
export class DataSourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `upsertDatasource()` */
  static readonly UpsertDatasourcePath = '/datasources/upsert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertDatasource()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertDatasource$Response(params?: UpsertDatasource$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return upsertDatasource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertDatasource$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertDatasource(params?: UpsertDatasource$Params, context?: HttpContext): Observable<void> {
    return this.upsertDatasource$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateDatasource()` */
  static readonly UpdateDatasourcePath = '/datasources/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDatasource()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateDatasource$Response(params?: UpdateDatasource$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return updateDatasource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDatasource$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateDatasource(params?: UpdateDatasource$Params, context?: HttpContext): Observable<void> {
    return this.updateDatasource$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `findDatasource()` */
  static readonly FindDatasourcePath = '/datasources/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findDatasource()` instead.
   *
   * This method doesn't expect any request body.
   */
  findDatasource$Response(params: FindDatasource$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingDataSourceView>> {
    return findDatasource(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findDatasource$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findDatasource(params: FindDatasource$Params, context?: HttpContext): Observable<LuklaCreditingDataSourceView> {
    return this.findDatasource$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingDataSourceView>): LuklaCreditingDataSourceView => r.body)
    );
  }

  /** Path part for operation `getDatasources()` */
  static readonly GetDatasourcesPath = '/datasources/many';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDatasources()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDatasources$Response(params?: GetDatasources$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingDataSourceView>>> {
    return getDatasources(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDatasources$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getDatasources(params?: GetDatasources$Params, context?: HttpContext): Observable<Array<LuklaCreditingDataSourceView>> {
    return this.getDatasources$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingDataSourceView>>): Array<LuklaCreditingDataSourceView> => r.body)
    );
  }

  /** Path part for operation `assetMaps()` */
  static readonly AssetMapsPath = '/asset/{assetId}/maps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assetMaps()` instead.
   *
   * This method doesn't expect any request body.
   */
  assetMaps$Response(params: AssetMaps$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingAssetDataMapView>>> {
    return assetMaps(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assetMaps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assetMaps(params: AssetMaps$Params, context?: HttpContext): Observable<Array<LuklaCreditingAssetDataMapView>> {
    return this.assetMaps$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingAssetDataMapView>>): Array<LuklaCreditingAssetDataMapView> => r.body)
    );
  }

  /** Path part for operation `attachDataFeed()` */
  static readonly AttachDataFeedPath = '/datasources/maps/attach-feed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachDataFeed()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachDataFeed$Response(params?: AttachDataFeed$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attachDataFeed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attachDataFeed$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachDataFeed(params?: AttachDataFeed$Params, context?: HttpContext): Observable<void> {
    return this.attachDataFeed$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createTaskFeed()` */
  static readonly CreateTaskFeedPath = '/datasources/maps/create-task-feed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTaskFeed()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaskFeed$Response(params?: CreateTaskFeed$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createTaskFeed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTaskFeed$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTaskFeed(params?: CreateTaskFeed$Params, context?: HttpContext): Observable<void> {
    return this.createTaskFeed$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `createDemoFeed()` */
  static readonly CreateDemoFeedPath = '/datasources/maps/create-demo-feed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDemoFeed()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createDemoFeed$Response(params?: CreateDemoFeed$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createDemoFeed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createDemoFeed$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createDemoFeed(params?: CreateDemoFeed$Params, context?: HttpContext): Observable<void> {
    return this.createDemoFeed$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `assetDataMap()` */
  static readonly AssetDataMapPath = '/datasources/map/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assetDataMap()` instead.
   *
   * This method doesn't expect any request body.
   */
  assetDataMap$Response(params: AssetDataMap$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingAssetDataMapGridView>> {
    return assetDataMap(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assetDataMap$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assetDataMap(params: AssetDataMap$Params, context?: HttpContext): Observable<LuklaCreditingAssetDataMapGridView> {
    return this.assetDataMap$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingAssetDataMapGridView>): LuklaCreditingAssetDataMapGridView => r.body)
    );
  }

  /** Path part for operation `attachInventoryItem()` */
  static readonly AttachInventoryItemPath = '/datasources/map/attach-inventory-item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachInventoryItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachInventoryItem$Response(params?: AttachInventoryItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attachInventoryItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attachInventoryItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachInventoryItem(params?: AttachInventoryItem$Params, context?: HttpContext): Observable<void> {
    return this.attachInventoryItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
