import { Injectable } from '@angular/core';
import { ProfileService } from '../api/services/profile.service';
import { ProfileView } from '../api/models/Lukla/Profile/profile-view';
import { OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from '../pages/auth/configuration';
import { Item as CompanyItem } from 'src/app/api/models/Lukla/Profile/GetCompanies/item';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  constructor(
      private profileService : ProfileService,
      private oauthService: OAuthService,
      private router: Router
  ) { }

  private current : ProfileView | null;

  async get() : Promise<ProfileView> {
      if (this.current) return this.current;
      this.current = await this.profileService.current().toPromise();
      return this.current;
  }

  async changeCompany(c: CompanyItem) {
    await this.profileService.switchCompany({companyId: c.id!}).toPromise();
    this.login();
  }

  login() {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    this.oauthService.logOut();
    this.oauthService.initLoginFlow();
    this.current = null;
  }

  isAdmin() {
    return this.current.roles.some(x => x === "Administrator");
  }
}