export const environment = {
  production: false,
  appName: 'DEV - CarbonAi Solutions',
  appId: '5fff7a6b-1c6f-45ff-9afa-b4dd1e4b991e',
  googleKey: 'AIzaSyCcp7_rTs3ENNwKE69c91DtOC7bvR3KoX8',
  api: 'https://gateways.crediting.dev.lukla.com',
  identityEndpoint: 'https://identity.dev.lukla.com',
  luklaDocumentUrl: 'https://app.lukla.co/details',
  tinymcekey: 's9mgynkayyur88nt7gtwowcbjchlfbb4x8cj554p1w8k3kmr',
  contentUploadEndpoint: 'https://api.inventory.dev.lukla.com/content/upload/version',
  ottotasksUrl: 'https://gateways.inventory.dev.lukla.com',
  nameForAsset: {
    singular: 'Asset',
    plural: 'Assets'
  },
  tasksUrl: 'https://tasks.dev.lukla.com',
};

