/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { assignedMapItems } from '../fn/query/assigned-map-items';
import { AssignedMapItems$Params } from '../fn/query/assigned-map-items';
import { basic } from '../fn/query/basic';
import { Basic$Params } from '../fn/query/basic';
import { detail } from '../fn/query/detail';
import { Detail$Params } from '../fn/query/detail';
import { full } from '../fn/query/full';
import { Full$Params } from '../fn/query/full';
import { listByReferenceItem } from '../fn/query/list-by-reference-item';
import { ListByReferenceItem$Params } from '../fn/query/list-by-reference-item';
import { TaskFindTasksResponse as LuklaTasksTaskFindTasksResponse } from '../models/Lukla/Tasks/task-find-tasks-response';
import { TaskTaskItemView as LuklaTasksTaskTaskItemView } from '../models/Lukla/Tasks/task-task-item-view';
import { TaskTaskListItem as LuklaTasksTaskTaskListItem } from '../models/Lukla/Tasks/task-task-list-item';
import { outstanding } from '../fn/query/outstanding';
import { Outstanding$Params } from '../fn/query/outstanding';
import { tasksQueriesFindTestPost } from '../fn/query/tasks-queries-find-test-post';
import { TasksQueriesFindTestPost$Params } from '../fn/query/tasks-queries-find-test-post';

@Injectable({ providedIn: 'root' })
export class TaskQueryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `detail()` */
  static readonly DetailPath = '/tasks/queries/{id}/details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `detail()` instead.
   *
   * This method doesn't expect any request body.
   */
  detail$Response(params: Detail$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaTasksTaskTaskItemView>> {
    return detail(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `detail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  detail(params: Detail$Params, context?: HttpContext): Observable<LuklaTasksTaskTaskItemView> {
    return this.detail$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaTasksTaskTaskItemView>): LuklaTasksTaskTaskItemView => r.body)
    );
  }

  /** Path part for operation `listByReferenceItem()` */
  static readonly ListByReferenceItemPath = '/tasks/queries/list/reference/item/{referenceItemId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listByReferenceItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  listByReferenceItem$Response(params: ListByReferenceItem$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaTasksTaskTaskListItem>>> {
    return listByReferenceItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listByReferenceItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listByReferenceItem(params: ListByReferenceItem$Params, context?: HttpContext): Observable<Array<LuklaTasksTaskTaskListItem>> {
    return this.listByReferenceItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaTasksTaskTaskListItem>>): Array<LuklaTasksTaskTaskListItem> => r.body)
    );
  }

  /** Path part for operation `assignedMapItems()` */
  static readonly AssignedMapItemsPath = '/tasks/queries/map/assigned';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assignedMapItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignedMapItems$Response(params?: AssignedMapItems$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaTasksTaskTaskListItem>>> {
    return assignedMapItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assignedMapItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  assignedMapItems(params?: AssignedMapItems$Params, context?: HttpContext): Observable<Array<LuklaTasksTaskTaskListItem>> {
    return this.assignedMapItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaTasksTaskTaskListItem>>): Array<LuklaTasksTaskTaskListItem> => r.body)
    );
  }

  /** Path part for operation `outstanding()` */
  static readonly OutstandingPath = '/tasks/queries/outstanding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `outstanding()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  outstanding$Response(params?: Outstanding$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaTasksTaskTaskListItem>>> {
    return outstanding(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `outstanding$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  outstanding(params?: Outstanding$Params, context?: HttpContext): Observable<Array<LuklaTasksTaskTaskListItem>> {
    return this.outstanding$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaTasksTaskTaskListItem>>): Array<LuklaTasksTaskTaskListItem> => r.body)
    );
  }

  /** Path part for operation `full()` */
  static readonly FullPath = '/tasks/queries/full';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `full()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  full$Response(params?: Full$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaTasksTaskTaskListItem>>> {
    return full(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `full$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  full(params?: Full$Params, context?: HttpContext): Observable<Array<LuklaTasksTaskTaskListItem>> {
    return this.full$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaTasksTaskTaskListItem>>): Array<LuklaTasksTaskTaskListItem> => r.body)
    );
  }

  /** Path part for operation `basic()` */
  static readonly BasicPath = '/tasks/queries/{id}/basic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basic()` instead.
   *
   * This method doesn't expect any request body.
   */
  basic$Response(params: Basic$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaTasksTaskTaskListItem>> {
    return basic(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  basic(params: Basic$Params, context?: HttpContext): Observable<LuklaTasksTaskTaskListItem> {
    return this.basic$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaTasksTaskTaskListItem>): LuklaTasksTaskTaskListItem => r.body)
    );
  }

  /** Path part for operation `tasksQueriesFindTestPost()` */
  static readonly TasksQueriesFindTestPostPath = '/tasks/queries/find/test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tasksQueriesFindTestPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tasksQueriesFindTestPost$Response(params?: TasksQueriesFindTestPost$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaTasksTaskFindTasksResponse>> {
    return tasksQueriesFindTestPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tasksQueriesFindTestPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tasksQueriesFindTestPost(params?: TasksQueriesFindTestPost$Params, context?: HttpContext): Observable<LuklaTasksTaskFindTasksResponse> {
    return this.tasksQueriesFindTestPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaTasksTaskFindTasksResponse>): LuklaTasksTaskFindTasksResponse => r.body)
    );
  }

}
