/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { approveValidation } from '../fn/validation/approve-validation';
import { ApproveValidation$Params } from '../fn/validation/approve-validation';
import { buildValidation } from '../fn/validation/build-validation';
import { BuildValidation$Params } from '../fn/validation/build-validation';
import { completeValidation } from '../fn/validation/complete-validation';
import { CompleteValidation$Params } from '../fn/validation/complete-validation';
import { findValidation } from '../fn/validation/find-validation';
import { FindValidation$Params } from '../fn/validation/find-validation';
import { getValidationPreview } from '../fn/validation/get-validation-preview';
import { GetValidationPreview$Params } from '../fn/validation/get-validation-preview';
import { getValidationSummary } from '../fn/validation/get-validation-summary';
import { GetValidationSummary$Params } from '../fn/validation/get-validation-summary';
import { ValidationSummaryView as LuklaCreditingValidationSummaryView } from '../models/Lukla/Crediting/validation-summary-view';
import { ValidationView as LuklaCreditingValidationView } from '../models/Lukla/Crediting/validation-view';
import { setUptime } from '../fn/validation/set-uptime';
import { SetUptime$Params } from '../fn/validation/set-uptime';
import { startValidation } from '../fn/validation/start-validation';
import { StartValidation$Params } from '../fn/validation/start-validation';

@Injectable({ providedIn: 'root' })
export class ValidationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `startValidation()` */
  static readonly StartValidationPath = '/validation/start';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startValidation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startValidation$Response(params?: StartValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return startValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startValidation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startValidation(params?: StartValidation$Params, context?: HttpContext): Observable<string> {
    return this.startValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `approveValidation()` */
  static readonly ApproveValidationPath = '/validation/approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveValidation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  approveValidation$Response(params?: ApproveValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return approveValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approveValidation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  approveValidation(params?: ApproveValidation$Params, context?: HttpContext): Observable<void> {
    return this.approveValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `completeValidation()` */
  static readonly CompleteValidationPath = '/validation/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeValidation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  completeValidation$Response(params?: CompleteValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return completeValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completeValidation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  completeValidation(params?: CompleteValidation$Params, context?: HttpContext): Observable<void> {
    return this.completeValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `buildValidation()` */
  static readonly BuildValidationPath = '/validation/build';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buildValidation()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildValidation$Response(params?: BuildValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return buildValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buildValidation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  buildValidation(params?: BuildValidation$Params, context?: HttpContext): Observable<void> {
    return this.buildValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getValidationPreview()` */
  static readonly GetValidationPreviewPath = '/validation/preview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValidationPreview()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getValidationPreview$Response(params?: GetValidationPreview$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingValidationSummaryView>> {
    return getValidationPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValidationPreview$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getValidationPreview(params?: GetValidationPreview$Params, context?: HttpContext): Observable<LuklaCreditingValidationSummaryView> {
    return this.getValidationPreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingValidationSummaryView>): LuklaCreditingValidationSummaryView => r.body)
    );
  }

  /** Path part for operation `getValidationSummary()` */
  static readonly GetValidationSummaryPath = '/validation/{id}/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValidationSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValidationSummary$Response(params: GetValidationSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingValidationSummaryView>> {
    return getValidationSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValidationSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValidationSummary(params: GetValidationSummary$Params, context?: HttpContext): Observable<LuklaCreditingValidationSummaryView> {
    return this.getValidationSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingValidationSummaryView>): LuklaCreditingValidationSummaryView => r.body)
    );
  }

  /** Path part for operation `findValidation()` */
  static readonly FindValidationPath = '/validation/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findValidation()` instead.
   *
   * This method doesn't expect any request body.
   */
  findValidation$Response(params: FindValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingValidationView>> {
    return findValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findValidation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findValidation(params: FindValidation$Params, context?: HttpContext): Observable<LuklaCreditingValidationView> {
    return this.findValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingValidationView>): LuklaCreditingValidationView => r.body)
    );
  }

  /** Path part for operation `setUptime()` */
  static readonly SetUptimePath = '/validation/uptime';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setUptime()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setUptime$Response(params?: SetUptime$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setUptime(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setUptime$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setUptime(params?: SetUptime$Params, context?: HttpContext): Observable<void> {
    return this.setUptime$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
