import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { IntervalDataType } from '../api/models/Lukla/Crediting/interval-data-type';

export enum DateResolution {
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Quarterly = 4,
    Yearly = 5
}

@Injectable()
export class SchedulerService {

    findGaps(periods: any[], startDate: string, endDate: string) {
        var gaps: any[] = [];
        var dates = periods.sort((a, b) => moment(a.startDate).unix() - moment(b.startDate).unix());
        if (dates.length === 0) {
            var daysBetween = moment(endDate).diff(moment(startDate), 'd');
            gaps.push({
                startDate: startDate,
                endDate: endDate,
                days: daysBetween
            });
            return gaps;
        }

        //  Determine if first period starts after start date
        var firstDate = dates[0];
        if (moment(firstDate.startDate).diff(moment(startDate), 'd') > 1) {
            gaps.push({
                startDate: startDate,
                endDate: moment(firstDate.startDate).subtract(1, 'day'),
            });
        }

        //  loop through current dates to find gaps
        for (var i = 0; i < dates.length - 1; i++) {
            var first = dates[i];
            var second = dates[i + 1];
            var daysBetween = moment(second.startDate).diff(moment(first.endDate), 'd');
            if (daysBetween > 1) {
                gaps.push({
                    startDate: moment(first.endDate).add(1, 'day'),
                    endDate: moment(second.startDate).subtract(1, 'day'),
                });
            }
        }

        //  Determine if last period ends on last period date
        var lastdate = dates[dates.length - 1];
        if (moment(endDate).diff(moment(lastdate.endDate), 'd') > 1) {
            gaps.push({
                startDate: moment(lastdate.endDate).add(1, 'day'),
                endDate: endDate
            });
        }

        gaps.forEach((x: any) => {
            x.days = moment(x.endDate).diff(moment(x.startDate), 'days')
        });

        return gaps;
    }

    determineResolution(startDate: string, endDate: string) {
        var numberOfMonths = moment(endDate).diff(startDate, 'month');
        var resolution = 1;
        if (numberOfMonths > 2) resolution = 2;
        if (numberOfMonths > 12) resolution = 3;
        if (numberOfMonths > 60) resolution = 4;
        if (numberOfMonths > 120) resolution = 5;
        return resolution;
    }

    buildData(a: any[], v: any[]) {
        var dates = a.map(x => moment(x.timestamp).utc().valueOf());
        var series: any[] = [];
        dates.forEach((x, i) => {
            var item = v[i];
            if (item) item = +item.toFixed(2);
            series.push([x, item]);
        });
        return series;
    }

    addAlpha(color: string, opacity: number): string {
        // coerce values so ti is between 0 and 1.
        const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
        return color + _opacity.toString(16).toUpperCase();
    }

    calculateWidth(totalWidth: number, periodDays: number, totalDays: number, totalPeriods: number = 1) {
        const baseWidth = (periodDays * totalWidth) / totalDays;
        const spacing = 10;
        const totalSpacing = spacing * (totalPeriods - 15);

        return Math.max(baseWidth - totalSpacing, 0);
    }

    generateDates(start: Date, end: Date, resolution: DateResolution): Date[] {
        const dates: Date[] = [];

        let currentDate = new Date(start);
        while (currentDate <= end) {
            dates.push(new Date(currentDate));

            switch (resolution) {
                case DateResolution.Daily:
                    currentDate.setDate(currentDate.getDate() + 1);
                    break;
                case DateResolution.Weekly:
                    currentDate.setDate(currentDate.getDate() + 7);
                    break;
                case DateResolution.Monthly:
                    currentDate.setMonth(currentDate.getMonth() + 1);
                    break;
                case DateResolution.Quarterly:
                    currentDate.setMonth(currentDate.getMonth() + 3);
                    break;
                case DateResolution.Yearly:
                    currentDate.setFullYear(currentDate.getFullYear() + 1);
                    break;
                default:
                    throw new Error('Invalid resolution');
            }
        }

        return dates;
    }
}
