/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addRoute } from '../fn/route/add-route';
import { AddRoute$Params } from '../fn/route/add-route';
import { clearRoute } from '../fn/route/clear-route';
import { ClearRoute$Params } from '../fn/route/clear-route';
import { getPosition } from '../fn/route/get-position';
import { GetPosition$Params } from '../fn/route/get-position';
import { TaskTaskPosition as LuklaTasksTaskTaskPosition } from '../models/Lukla/Tasks/task-task-position';
import { setPosition } from '../fn/route/set-position';
import { SetPosition$Params } from '../fn/route/set-position';

@Injectable({ providedIn: 'root' })
export class TaskRouteService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `setPosition()` */
  static readonly SetPositionPath = '/tasks/route/position';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPosition()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setPosition$Response(params?: SetPosition$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setPosition(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setPosition$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  setPosition(params?: SetPosition$Params, context?: HttpContext): Observable<void> {
    return this.setPosition$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `clearRoute()` */
  static readonly ClearRoutePath = '/tasks/route/clear';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clearRoute()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clearRoute$Response(params?: ClearRoute$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return clearRoute(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clearRoute$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  clearRoute(params?: ClearRoute$Params, context?: HttpContext): Observable<void> {
    return this.clearRoute$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addRoute()` */
  static readonly AddRoutePath = '/tasks/route/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRoute()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRoute$Response(params?: AddRoute$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return addRoute(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRoute$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRoute(params?: AddRoute$Params, context?: HttpContext): Observable<void> {
    return this.addRoute$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getPosition()` */
  static readonly GetPositionPath = '/tasks/route/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPosition()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPosition$Response(params: GetPosition$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaTasksTaskTaskPosition>> {
    return getPosition(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPosition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPosition(params: GetPosition$Params, context?: HttpContext): Observable<LuklaTasksTaskTaskPosition> {
    return this.getPosition$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaTasksTaskTaskPosition>): LuklaTasksTaskTaskPosition => r.body)
    );
  }

}
