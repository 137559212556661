/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { affected } from '../fn/action/affected';
import { Affected$Params } from '../fn/action/affected';
import { assign } from '../fn/action/assign';
import { Assign$Params } from '../fn/action/assign';
import { canNotComplete } from '../fn/action/can-not-complete';
import { CanNotComplete$Params } from '../fn/action/can-not-complete';
import { close } from '../fn/action/close';
import { Close$Params } from '../fn/action/close';
import { complete } from '../fn/action/complete';
import { Complete$Params } from '../fn/action/complete';
import { create } from '../fn/action/create';
import { Create$Params } from '../fn/action/create';
import { delete$ } from '../fn/action/delete';
import { Delete$Params } from '../fn/action/delete';
import { TaskTaskListItem as LuklaTasksTaskTaskListItem } from '../models/Lukla/Tasks/task-task-list-item';
import { reject } from '../fn/action/reject';
import { Reject$Params } from '../fn/action/reject';
import { release } from '../fn/action/release';
import { Release$Params } from '../fn/action/release';
import { revert } from '../fn/action/revert';
import { Revert$Params } from '../fn/action/revert';
import { start } from '../fn/action/start';
import { Start$Params } from '../fn/action/start';
import { suspend } from '../fn/action/suspend';
import { Suspend$Params } from '../fn/action/suspend';
import { update } from '../fn/action/update';
import { Update$Params } from '../fn/action/update';

@Injectable({ providedIn: 'root' })
export class TaskActionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `create()` */
  static readonly CreatePath = '/tasks/actions/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaTasksTaskTaskListItem>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<LuklaTasksTaskTaskListItem> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaTasksTaskTaskListItem>): LuklaTasksTaskTaskListItem => r.body)
    );
  }

  /** Path part for operation `update()` */
  static readonly UpdatePath = '/tasks/actions/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `update()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update$Response(params?: Update$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return update(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `update$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  update(params?: Update$Params, context?: HttpContext): Observable<void> {
    return this.update$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `affected()` */
  static readonly AffectedPath = '/tasks/actions/affected';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `affected()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  affected$Response(params?: Affected$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return affected(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `affected$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  affected(params?: Affected$Params, context?: HttpContext): Observable<void> {
    return this.affected$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `assign()` */
  static readonly AssignPath = '/tasks/actions/assign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assign()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assign$Response(params?: Assign$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return assign(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assign$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assign(params?: Assign$Params, context?: HttpContext): Observable<void> {
    return this.assign$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `start()` */
  static readonly StartPath = '/tasks/actions/start';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `start()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  start$Response(params?: Start$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return start(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `start$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  start(params?: Start$Params, context?: HttpContext): Observable<void> {
    return this.start$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `suspend()` */
  static readonly SuspendPath = '/tasks/actions/suspend';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `suspend()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  suspend$Response(params?: Suspend$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return suspend(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `suspend$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  suspend(params?: Suspend$Params, context?: HttpContext): Observable<void> {
    return this.suspend$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `reject()` */
  static readonly RejectPath = '/tasks/actions/reject';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reject()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reject$Response(params?: Reject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return reject(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reject$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  reject(params?: Reject$Params, context?: HttpContext): Observable<void> {
    return this.reject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `release()` */
  static readonly ReleasePath = '/tasks/actions/release';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `release()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  release$Response(params?: Release$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return release(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `release$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  release(params?: Release$Params, context?: HttpContext): Observable<void> {
    return this.release$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `revert()` */
  static readonly RevertPath = '/tasks/actions/revert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revert()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  revert$Response(params?: Revert$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return revert(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `revert$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  revert(params?: Revert$Params, context?: HttpContext): Observable<void> {
    return this.revert$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `complete()` */
  static readonly CompletePath = '/tasks/actions/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `complete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  complete$Response(params?: Complete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return complete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `complete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  complete(params?: Complete$Params, context?: HttpContext): Observable<void> {
    return this.complete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `canNotComplete()` */
  static readonly CanNotCompletePath = '/tasks/actions/can-not-complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `canNotComplete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  canNotComplete$Response(params?: CanNotComplete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return canNotComplete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `canNotComplete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  canNotComplete(params?: CanNotComplete$Params, context?: HttpContext): Observable<void> {
    return this.canNotComplete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `close()` */
  static readonly ClosePath = '/tasks/actions/close';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `close()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  close$Response(params?: Close$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return close(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `close$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  close(params?: Close$Params, context?: HttpContext): Observable<void> {
    return this.close$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `delete()` */
  static readonly DeletePath = '/tasks/actions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete$Response(params?: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  delete(params?: Delete$Params, context?: HttpContext): Observable<void> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
