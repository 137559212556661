/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { auditAsset } from '../fn/calculation/audit-asset';
import { AuditAsset$Params } from '../fn/calculation/audit-asset';
import { calculate } from '../fn/calculation/calculate';
import { Calculate$Params } from '../fn/calculation/calculate';
import { calculateCompany } from '../fn/calculation/calculate-company';
import { CalculateCompany$Params } from '../fn/calculation/calculate-company';
import { calculatePeriod } from '../fn/calculation/calculate-period';
import { CalculatePeriod$Params } from '../fn/calculation/calculate-period';
import { execute } from '../fn/calculation/execute';
import { Execute$Params } from '../fn/calculation/execute';
import { fetchAsset } from '../fn/calculation/fetch-asset';
import { FetchAsset$Params } from '../fn/calculation/fetch-asset';
import { fetchVerification } from '../fn/calculation/fetch-verification';
import { FetchVerification$Params } from '../fn/calculation/fetch-verification';
import { AssetCalculationResult as LuklaCreditingAssetCalculationResult } from '../models/Lukla/Crediting/asset-calculation-result';
import { AuditResult as LuklaCreditingAuditResult } from '../models/Lukla/Crediting/audit-result';
import { OffsetCalculationResult as LuklaCreditingOffsetCalculationResult } from '../models/Lukla/Crediting/offset-calculation-result';
import { parseCalculation } from '../fn/calculation/parse-calculation';
import { ParseCalculation$Params } from '../fn/calculation/parse-calculation';
import { parseTemplate } from '../fn/calculation/parse-template';
import { ParseTemplate$Params } from '../fn/calculation/parse-template';

@Injectable({ providedIn: 'root' })
export class CalculationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `calculate()` */
  static readonly CalculatePath = '/calculation/asset/calculate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  calculate$Response(params?: Calculate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return calculate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `calculate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  calculate(params?: Calculate$Params, context?: HttpContext): Observable<void> {
    return this.calculate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `calculateCompany()` */
  static readonly CalculateCompanyPath = '/calculation/company/calculate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  calculateCompany$Response(params?: CalculateCompany$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return calculateCompany(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `calculateCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  calculateCompany(params?: CalculateCompany$Params, context?: HttpContext): Observable<void> {
    return this.calculateCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `calculatePeriod()` */
  static readonly CalculatePeriodPath = '/calculation/period/calculate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculatePeriod()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  calculatePeriod$Response(params?: CalculatePeriod$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return calculatePeriod(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `calculatePeriod$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  calculatePeriod(params?: CalculatePeriod$Params, context?: HttpContext): Observable<void> {
    return this.calculatePeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `parseCalculation()` */
  static readonly ParseCalculationPath = '/calculation/parse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parseCalculation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  parseCalculation$Response(params?: ParseCalculation$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return parseCalculation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parseCalculation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  parseCalculation(params?: ParseCalculation$Params, context?: HttpContext): Observable<void> {
    return this.parseCalculation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `parseTemplate()` */
  static readonly ParseTemplatePath = '/calculation/parse/template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parseTemplate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  parseTemplate$Response(params?: ParseTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return parseTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `parseTemplate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  parseTemplate(params?: ParseTemplate$Params, context?: HttpContext): Observable<void> {
    return this.parseTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `execute()` */
  static readonly ExecutePath = '/calculation/execute';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `execute()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  execute$Response(params?: Execute$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingOffsetCalculationResult>> {
    return execute(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `execute$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  execute(params?: Execute$Params, context?: HttpContext): Observable<LuklaCreditingOffsetCalculationResult> {
    return this.execute$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingOffsetCalculationResult>): LuklaCreditingOffsetCalculationResult => r.body)
    );
  }

  /** Path part for operation `fetchAsset()` */
  static readonly FetchAssetPath = '/calculation/fetch/asset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchAsset()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fetchAsset$Response(params?: FetchAsset$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingAssetCalculationResult>>> {
    return fetchAsset(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetchAsset$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fetchAsset(params?: FetchAsset$Params, context?: HttpContext): Observable<Array<LuklaCreditingAssetCalculationResult>> {
    return this.fetchAsset$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingAssetCalculationResult>>): Array<LuklaCreditingAssetCalculationResult> => r.body)
    );
  }

  /** Path part for operation `fetchVerification()` */
  static readonly FetchVerificationPath = '/calculation/fetch/verification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchVerification()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fetchVerification$Response(params?: FetchVerification$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingAssetCalculationResult>>> {
    return fetchVerification(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fetchVerification$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  fetchVerification(params?: FetchVerification$Params, context?: HttpContext): Observable<Array<LuklaCreditingAssetCalculationResult>> {
    return this.fetchVerification$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingAssetCalculationResult>>): Array<LuklaCreditingAssetCalculationResult> => r.body)
    );
  }

  /** Path part for operation `auditAsset()` */
  static readonly AuditAssetPath = '/calculation/audit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auditAsset()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  auditAsset$Response(params?: AuditAsset$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingAuditResult>>> {
    return auditAsset(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auditAsset$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  auditAsset(params?: AuditAsset$Params, context?: HttpContext): Observable<Array<LuklaCreditingAuditResult>> {
    return this.auditAsset$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingAuditResult>>): Array<LuklaCreditingAuditResult> => r.body)
    );
  }

}
