/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { exportAssetData } from '../fn/export/export-asset-data';
import { ExportAssetData$Params } from '../fn/export/export-asset-data';
import { exportValidation } from '../fn/export/export-validation';
import { ExportValidation$Params } from '../fn/export/export-validation';
import { exportVerification } from '../fn/export/export-verification';
import { ExportVerification$Params } from '../fn/export/export-verification';

@Injectable({ providedIn: 'root' })
export class ExportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `exportAssetData()` */
  static readonly ExportAssetDataPath = '/export/asset/data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportAssetData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exportAssetData$Response(params?: ExportAssetData$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportAssetData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportAssetData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  exportAssetData(params?: ExportAssetData$Params, context?: HttpContext): Observable<Blob> {
    return this.exportAssetData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `exportVerification()` */
  static readonly ExportVerificationPath = '/export/verification/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportVerification()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportVerification$Response(params: ExportVerification$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportVerification(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportVerification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportVerification(params: ExportVerification$Params, context?: HttpContext): Observable<Blob> {
    return this.exportVerification$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `exportValidation()` */
  static readonly ExportValidationPath = '/export/validation/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportValidation()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportValidation$Response(params: ExportValidation$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return exportValidation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportValidation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportValidation(params: ExportValidation$Params, context?: HttpContext): Observable<Blob> {
    return this.exportValidation$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
