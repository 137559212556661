import { Component, EventEmitter, Input, Output, ElementRef } from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'debounced-input',
    template: '<input type="text" class="form-control" [placeholder]="placeholder" [(ngModel)]="inputValue">'
})
export class DeboucedInputComponent {
    @Input() placeholder: string;
    @Input() delay: number = 300;
    @Output() value = new EventEmitter<any>();

    public inputValue: string;

    constructor(private elementRef: ElementRef) {
        const eventStream = fromEvent(elementRef.nativeElement, 'keyup').pipe(
            map(() => this.inputValue),
            debounceTime(this.delay),
            distinctUntilChanged());

        eventStream.subscribe(input => this.value.emit(input));
    }
}