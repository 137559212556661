<header class="app-header d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <div>
        <img src="assets/proof-by-carbonai.png" height="60" alt="Carbon Ai Solutions">
      </div>

      <ng-container *ngIf="!noInteraction">
        <div class="d-flex flex-row app-header-actions">
          <!-- <li class="nav-item d-flex justify-content-start align-items-center">
            <a class="btn btn-link action-button" [routerLink]="'/items'" [routerLinkActive]="['is-active']"
              [routerLinkActiveOptions]="{exact: true}" tooltip="Assets" container="body">
              <fa-icon [icon]="assetIcon"></fa-icon>
            </a>
            <h3 class="d-lg-none fw-light d-xxl-block">{{nameForAsset.plural}}</h3>
          </li> -->
          <!-- <li class="nav-item d-flex justify-content-start align-items-center">
            <a class="btn btn-link action-button" [routerLink]="'/incidents/active'" [routerLinkActive]="['is-active']"
              [routerLinkActiveOptions]="{exact: true}" tooltip="Incidents" container="body">
              <fa-icon [icon]="incidentIcon"></fa-icon>
            </a>
            <h3 class="d-lg-none fw-light d-xxl-block">Incidents</h3>
          </li> -->
          <!-- <li class="nav-item d-flex justify-content-start align-items-center">
            <a class="btn btn-link action-button" [routerLink]="'/projects'" [routerLinkActive]="['is-active']"
              [routerLinkActiveOptions]="{exact: true}" tooltip="Projects" container="body">
              <fa-icon [icon]="projectIcon"></fa-icon>
            </a>
            <h3 class="d-lg-none fw-light d-xxl-block">Projects</h3>
          </li> -->
          <!-- <li class="nav-item d-flex justify-content-start align-items-center" *ngIf="isAdmin">
            <a class="btn btn-link action-button" [routerLink]="'/admin'" [routerLinkActive]="['is-active']"
              [routerLinkActiveOptions]="{exact: true}" tooltip="Administration" container="body">
              <fa-icon [icon]="adminIcon"></fa-icon>
            </a>
            <h3 class="d-lg-none fw-light d-xxl-block">Administration</h3>
          </li> -->
        </div>
      </ng-container>
    </div>
    <div *ngIf="currentUser && !noInteraction" class="d-flex align-items-center app-header-actions">
      <button class="btn btn-link mr-2 ml-0" (click)="logout()" tooltip="Logout">
        <fa-icon [icon]="logoutIcon"></fa-icon>
      </button>
      <lukla-header-profile></lukla-header-profile>
    </div>

  </header>
