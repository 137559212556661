import { Injectable } from "@angular/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCertificate, faCheck, faPlay } from "@fortawesome/pro-solid-svg-icons";
import { VerificationStatusEnum } from "./verification-status-enum";

@Injectable()
export class VerificationDisplayService {

    setStatusName(item: any): string {
        switch (<VerificationStatusEnum>item.status) {
          case VerificationStatusEnum.InProgress: return "In Progress";
          case VerificationStatusEnum.Completed: return "Completed";
          default: return "Not Started";
        }
      }
    
      setStatusIcon(item: any): IconDefinition {
        switch (<VerificationStatusEnum>item.status) {
          case VerificationStatusEnum.InProgress: return faPlay;
          case VerificationStatusEnum.Completed: return faCheck;
          default: return faCertificate;
        }
      }
    
      setStatusClass(item: any): string {
        switch (<VerificationStatusEnum>item.status) {
          case VerificationStatusEnum.InProgress: return "primary";
          case VerificationStatusEnum.Completed: return "success";
          default: return 'dark';
        }
      }
}
