import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DefineAssignTagComponent } from './presentation/actions/define-assign-tag/define-assign-tag.component';
import { DefineCheckConditionComponent } from './presentation/actions/define-check-condition/define-check-condition.component';
import { DefineCheckComponent } from './presentation/actions/define-check/define-check.component';
import { DefineConditionalComponent } from './presentation/actions/define-conditional/define-conditional.component';
import { DefineExceptionComponent } from './presentation/actions/define-exception/define-exception.component';
import { DefineFileComponent } from './presentation/actions/define-file/define-file.component';
import { DefineGeolocationComponent } from './presentation/actions/define-geolocation/define-geolocation.component';
import { DefineInventoryComponent } from './presentation/actions/define-inventory/define-inventory.component';
import { DefinePhotoComponent } from './presentation/actions/define-photo/define-photo.component';
import { DefineSectionComponent } from './presentation/actions/define-section/define-section.component';
import { DefineStepComponent } from './presentation/actions/define-step/define-step.component';
import { DefineVerifyConditionComponent } from './presentation/actions/define-verify-condition/define-verify-condition.component';
import { DefineVerifyDataComponent } from './presentation/actions/define-verify-data/define-verify-data.component';
import { EndWorkflowComponent } from './presentation/actions/end-workflow/end-workflow.component';
import { SelectActionModalComponent } from './presentation/actions/select-action-modal/select-action-modal.component';
import { SelectActionComponent } from './presentation/actions/select-action/select-action.component';
import { DefineCommentsComponent } from './presentation/actions/define-comments/define-comments.component';
import { TemplateDetailsComponent } from './presentation/template-details/template-details.component';
import { TemplateUpdateService } from './services/template-update-service';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgPipesModule } from 'ngx-pipes';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TemplateFiltersComponent } from './presentation/template-filters/template-filters.component';
import { TemplateHeaderComponent } from './presentation/template-header/template-header.component';
import { TemplateListItemComponent } from './presentation/template-list-item/template-list-item.component';
import { TemplateSelectorComponent } from './presentation/template-selector/template-selector.component';
import { TemplateVersionsComponent } from './smart/template-versions/template-versions.component';
import { TemplateViewerComponent } from './smart/template-viewer/template-viewer.component';
import { TemplateWizardComponent } from './smart/template-wizard/template-wizard.component';
import { TemplateDefinitionComponent } from './presentation/template-definition/template-definition.component';
import { UtilitiesModule } from '../utilities/utilities.module';
import { MomentModule } from 'ngx-moment';
import { TemplateReadonlyComponent } from './smart/template-readonly/template-readonly.component';

@NgModule({
  declarations: [
    TemplateDefinitionComponent,
    TemplateWizardComponent,
    TemplateDetailsComponent,
    TemplateListItemComponent,
    TemplateViewerComponent,
    TemplateFiltersComponent,
    TemplateHeaderComponent,
    TemplateVersionsComponent,
    TemplateSelectorComponent,
    DefineSectionComponent,
    DefineGeolocationComponent,
    DefineStepComponent,
    DefineAssignTagComponent,
    DefineCheckComponent,
    DefineVerifyDataComponent,
    DefinePhotoComponent,
    DefineFileComponent,
    DefineExceptionComponent,
    DefineCheckConditionComponent,
    DefineConditionalComponent,
    DefineVerifyConditionComponent,
    DefineCommentsComponent,
    EndWorkflowComponent,
    DefineInventoryComponent,
    SelectActionComponent,
    SelectActionModalComponent,
    TemplateReadonlyComponent
    
    
  ],
  imports: [
    UtilitiesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    EditorModule,
    NgSelectModule,
    NgPipesModule,
    CollapseModule,
    NgPipesModule,
    TooltipModule,
    MomentModule,
    
  ],
  exports: [
    TemplateWizardComponent,
    TemplateDetailsComponent,
    TemplateListItemComponent,
    TemplateViewerComponent,
    TemplateFiltersComponent,
    TemplateHeaderComponent,
    TemplateVersionsComponent,
    TemplateSelectorComponent,
    TemplateDefinitionComponent,
    DefineStepComponent,
    TemplateReadonlyComponent
   
  ],
  providers: [ 
    TemplateUpdateService
  ],
})
export class TaskTemplatesModule { }
