/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AnalysisService } from './services/analysis.service';
import { AssetDataService } from './services/asset-data.service';
import { AssetsService } from './services/assets.service';
import { CalculationService } from './services/calculation.service';
import { CalculatorService } from './services/calculator.service';
import { CreditingDataService } from './services/crediting-data.service';
import { DataSourceService } from './services/data-source.service';
import { ExpectationService } from './services/expectation.service';
import { ExportService } from './services/export.service';
import { ExtractionService } from './services/extraction.service';
import { MethodologyService } from './services/methodology.service';
import { ProjectService } from './services/project.service';
import { ReportingPeriodsService } from './services/reporting-periods.service';
import { TechnicianAccessService } from './services/technician-access.service';
import { ValidationService } from './services/validation.service';
import { ValidationAccessService } from './services/validation-access.service';
import { VerificationService } from './services/verification.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AnalysisService,
    AssetDataService,
    AssetsService,
    CalculationService,
    CalculatorService,
    CreditingDataService,
    DataSourceService,
    ExpectationService,
    ExportService,
    ExtractionService,
    MethodologyService,
    ProjectService,
    ReportingPeriodsService,
    TechnicianAccessService,
    ValidationService,
    ValidationAccessService,
    VerificationService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
