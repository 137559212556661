import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-root',
  standalone: true,
  template: `<router-outlet />`,
  styles: [''],
  imports: [RouterOutlet]
})
export class AppComponent {
  constructor(faConfig: FaConfig, library: FaIconLibrary) {
    faConfig.defaultPrefix = 'fas';
    library.addIconPacks(fas);
  }
}
