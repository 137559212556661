/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { extract } from '../fn/extraction/extract';
import { Extract$Params } from '../fn/extraction/extract';
import { extractAll } from '../fn/extraction/extract-all';
import { ExtractAll$Params } from '../fn/extraction/extract-all';
import { extractAssets } from '../fn/extraction/extract-assets';
import { ExtractAssets$Params } from '../fn/extraction/extract-assets';
import { extractEntire } from '../fn/extraction/extract-entire';
import { ExtractEntire$Params } from '../fn/extraction/extract-entire';
import { extractPeriod } from '../fn/extraction/extract-period';
import { ExtractPeriod$Params } from '../fn/extraction/extract-period';
import { registerExtraction } from '../fn/extraction/register-extraction';
import { RegisterExtraction$Params } from '../fn/extraction/register-extraction';
import { testIot } from '../fn/extraction/test-iot';
import { TestIot$Params } from '../fn/extraction/test-iot';

@Injectable({ providedIn: 'root' })
export class ExtractionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `extract()` */
  static readonly ExtractPath = '/extraction/extract';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `extract()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extract$Response(params?: Extract$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return extract(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `extract$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extract(params?: Extract$Params, context?: HttpContext): Observable<void> {
    return this.extract$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `extractAssets()` */
  static readonly ExtractAssetsPath = '/extraction/extract/bulk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `extractAssets()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extractAssets$Response(params?: ExtractAssets$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return extractAssets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `extractAssets$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extractAssets(params?: ExtractAssets$Params, context?: HttpContext): Observable<void> {
    return this.extractAssets$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `extractPeriod()` */
  static readonly ExtractPeriodPath = '/extraction/extract/period';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `extractPeriod()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extractPeriod$Response(params?: ExtractPeriod$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return extractPeriod(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `extractPeriod$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extractPeriod(params?: ExtractPeriod$Params, context?: HttpContext): Observable<void> {
    return this.extractPeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `extractEntire()` */
  static readonly ExtractEntirePath = '/extraction/extract/entire';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `extractEntire()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extractEntire$Response(params?: ExtractEntire$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return extractEntire(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `extractEntire$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  extractEntire(params?: ExtractEntire$Params, context?: HttpContext): Observable<void> {
    return this.extractEntire$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `extractAll()` */
  static readonly ExtractAllPath = '/extraction/extract/assets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `extractAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  extractAll$Response(params?: ExtractAll$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return extractAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `extractAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  extractAll(params?: ExtractAll$Params, context?: HttpContext): Observable<void> {
    return this.extractAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registerExtraction()` */
  static readonly RegisterExtractionPath = '/extraction/register/extraction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerExtraction()` instead.
   *
   * This method doesn't expect any request body.
   */
  registerExtraction$Response(params?: RegisterExtraction$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registerExtraction(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerExtraction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registerExtraction(params?: RegisterExtraction$Params, context?: HttpContext): Observable<void> {
    return this.registerExtraction$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `testIot()` */
  static readonly TestIotPath = '/extraction/extract/test-iot';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `testIot()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  testIot$Response(params?: TestIot$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return testIot(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `testIot$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  testIot(params?: TestIot$Params, context?: HttpContext): Observable<string> {
    return this.testIot$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
