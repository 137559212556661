/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { findVerification } from '../fn/verification/find-verification';
import { FindVerification$Params } from '../fn/verification/find-verification';
import { Response as LuklaCreditingStartVerificationResponse } from '../models/Lukla/Crediting/StartVerification/response';
import { VerificationSummaryView as LuklaCreditingVerificationSummaryView } from '../models/Lukla/Crediting/verification-summary-view';
import { VerificationView as LuklaCreditingVerificationView } from '../models/Lukla/Crediting/verification-view';
import { periodSummary } from '../fn/verification/period-summary';
import { PeriodSummary$Params } from '../fn/verification/period-summary';
import { startVerification } from '../fn/verification/start-verification';
import { StartVerification$Params } from '../fn/verification/start-verification';

@Injectable({ providedIn: 'root' })
export class VerificationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `startVerification()` */
  static readonly StartVerificationPath = '/verification/start';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startVerification()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startVerification$Response(params?: StartVerification$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingStartVerificationResponse>> {
    return startVerification(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startVerification$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startVerification(params?: StartVerification$Params, context?: HttpContext): Observable<LuklaCreditingStartVerificationResponse> {
    return this.startVerification$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingStartVerificationResponse>): LuklaCreditingStartVerificationResponse => r.body)
    );
  }

  /** Path part for operation `findVerification()` */
  static readonly FindVerificationPath = '/verification/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findVerification()` instead.
   *
   * This method doesn't expect any request body.
   */
  findVerification$Response(params: FindVerification$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingVerificationView>> {
    return findVerification(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findVerification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findVerification(params: FindVerification$Params, context?: HttpContext): Observable<LuklaCreditingVerificationView> {
    return this.findVerification$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingVerificationView>): LuklaCreditingVerificationView => r.body)
    );
  }

  /** Path part for operation `periodSummary()` */
  static readonly PeriodSummaryPath = '/verification/{periodId}/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `periodSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  periodSummary$Response(params: PeriodSummary$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingVerificationSummaryView>> {
    return periodSummary(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `periodSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  periodSummary(params: PeriodSummary$Params, context?: HttpContext): Observable<LuklaCreditingVerificationSummaryView> {
    return this.periodSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingVerificationSummaryView>): LuklaCreditingVerificationSummaryView => r.body)
    );
  }

}
