/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createCalculator } from '../fn/calculator/create-calculator';
import { CreateCalculator$Params } from '../fn/calculator/create-calculator';
import { getCalculatorTemplate } from '../fn/calculator/get-calculator-template';
import { GetCalculatorTemplate$Params } from '../fn/calculator/get-calculator-template';
import { getLatestTemplate } from '../fn/calculator/get-latest-template';
import { GetLatestTemplate$Params } from '../fn/calculator/get-latest-template';
import { CalculatorTemplate as LuklaCreditingCalculatorTemplate } from '../models/Lukla/Crediting/calculator-template';
import { upsertCalculatorTemplate } from '../fn/calculator/upsert-calculator-template';
import { UpsertCalculatorTemplate$Params } from '../fn/calculator/upsert-calculator-template';

@Injectable({ providedIn: 'root' })
export class CalculatorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createCalculator()` */
  static readonly CreateCalculatorPath = '/calculator/calculator/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCalculator()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCalculator$Response(params?: CreateCalculator$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createCalculator(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCalculator$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createCalculator(params?: CreateCalculator$Params, context?: HttpContext): Observable<void> {
    return this.createCalculator$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getLatestTemplate()` */
  static readonly GetLatestTemplatePath = '/calculator/calculator/{id}/template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLatestTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestTemplate$Response(params: GetLatestTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingCalculatorTemplate>> {
    return getLatestTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLatestTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLatestTemplate(params: GetLatestTemplate$Params, context?: HttpContext): Observable<LuklaCreditingCalculatorTemplate> {
    return this.getLatestTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingCalculatorTemplate>): LuklaCreditingCalculatorTemplate => r.body)
    );
  }

  /** Path part for operation `upsertCalculatorTemplate()` */
  static readonly UpsertCalculatorTemplatePath = '/calculator/template/upsert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upsertCalculatorTemplate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertCalculatorTemplate$Response(params?: UpsertCalculatorTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return upsertCalculatorTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `upsertCalculatorTemplate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  upsertCalculatorTemplate(params?: UpsertCalculatorTemplate$Params, context?: HttpContext): Observable<void> {
    return this.upsertCalculatorTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getCalculatorTemplate()` */
  static readonly GetCalculatorTemplatePath = '/calculator/template/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalculatorTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculatorTemplate$Response(params: GetCalculatorTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingCalculatorTemplate>> {
    return getCalculatorTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalculatorTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculatorTemplate(params: GetCalculatorTemplate$Params, context?: HttpContext): Observable<LuklaCreditingCalculatorTemplate> {
    return this.getCalculatorTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingCalculatorTemplate>): LuklaCreditingCalculatorTemplate => r.body)
    );
  }

}
