import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Response } from 'src/app/api/models/Lukla/Profile/Search/response';
import { ProfileView } from 'src/app/api/models/Lukla/Profile/profile-view';
import { ProfileService } from 'src/app/api/services/profile.service';

@Component({
  selector: 'lukla-person-picker',
  templateUrl: './person-picker.component.html',
  styleUrls: ['./person-picker.component.scss']
})
export class PersonPickerComponent implements OnInit {

  constructor(
    private profileService : ProfileService
  ) { }

  @Input() allowMultiple = false;
  @Input() allowExternal = false;
  @Input() email = "";
  @Output() emailChange = new EventEmitter();
  @Input() emails: string[] = [];
  @Output() emailsChange = new EventEmitter();
  @Output() change = new EventEmitter();

  items$: Observable<ProfileView[]>;
  loading = false;
  searchInput$ = new Subject<string>();
  selectedItem: any;

  ngOnInit(): void { 
    this.loadItems();
  }

  trackByFn(item: ProfileView) {
    return item.userName;
  }

  private loadItems() {
    this.items$ = concat(
      of([]), // default items
      this.searchInput$.pipe(
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap(term => this.profileService.search({SearchTerms: term}).pipe(
          catchError(() => []), // empty list on error
          tap((x: Response) => this.loading = false),
          map((v: Response) =>  v.items!)          
        ))
      )
    );
  }

  onChange(p: any) {
    if (!this.allowMultiple) {
      this.email = p.userName;
      this.emailChange.emit(p.userName);
      this.change.emit(this.email);
    }
    else {
      this.emails = p.map((v:any) => v.userName);
      this.emailsChange.emit(this.emails);
      this.change.emit(this.emails);
    }
  }
}
