import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { AuthGuard, AuthPermissionService } from './auth/auth-guard';
import {
  LuklaPermissionService,
  LuklaUserGuard,
} from './auth/lukla-user-guard';
import { CurrentUserService } from '../lukla/user.service';
import { UnauthorizedComponent } from '../pages/redirects/unauthorized/unauthorized.component';
import { ErrorComponent } from '../pages/redirects/error/error.component';
import { UnknownComponent } from '../pages/redirects/unknown/unknown.component';
import { NoAccessComponent } from '../pages/redirects/no-access/no-access.component';
import { LuklaModule } from '../lukla/lukla.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DeboucedInputComponent } from './debouced-input/debouced-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { GoogleMapsModule } from '@angular/google-maps';
import { SchedulerService } from './scheduler.service';
import { HighchartsChartModule } from 'highcharts-angular';
import { MomentModule } from 'ngx-moment';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { MathjaxModule } from 'mathjax-angular';
import { VerificationDisplayService } from './verification-display.service';
import { NonZeroPipe } from './non-zero-pipe';
import { NgPipesModule } from 'ngx-pipes';
import { TechnicianPermissionService } from './auth/technician-guard';
import { ValidatorPermissionService } from './auth/validator-guard';

@NgModule({
  declarations: [
    HeaderBarComponent,
    DeboucedInputComponent
  ],
  imports: [
    CommonModule,
    LuklaModule,
    FontAwesomeModule,
    FormsModule,
    NgPipesModule,
    ReactiveFormsModule,
    ContentLoaderModule,
    RouterModule,
    NgSelectModule,
    HighchartsChartModule,
    MomentModule,
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
    GoogleMapsModule,
    MathjaxModule.forChild(),    
    NonZeroPipe
  ],
  exports: [
    CommonModule,
    LuklaModule,
    FontAwesomeModule,
    ContentLoaderModule,
    FormsModule,
    PopoverModule,
    ReactiveFormsModule,
    RouterModule,
    HeaderBarComponent,
    DeboucedInputComponent,
    BsDatepickerModule,
    BsDropdownModule,
    TooltipModule,
    NgPipesModule,
    MomentModule,
    HighchartsChartModule,
    ModalModule,
    NgSelectModule,
    ToastrModule,
    GoogleMapsModule,
    MathjaxModule,
    NonZeroPipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      providers: [
        AuthPermissionService,
        LuklaPermissionService,
        TechnicianPermissionService,
        ValidatorPermissionService,
        CurrentUserService,
        SchedulerService,
        VerificationDisplayService,
      ],
      ngModule: SharedModule,
    };
  }
}
