/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { assetData } from '../fn/crediting-data/asset-data';
import { AssetData$Params } from '../fn/crediting-data/asset-data';
import { assetsData } from '../fn/crediting-data/assets-data';
import { AssetsData$Params } from '../fn/crediting-data/assets-data';
import { AggregationDataView as LuklaCreditingAggregationDataView } from '../models/Lukla/Crediting/aggregation-data-view';
import { AssetDataView as LuklaCreditingAssetDataView } from '../models/Lukla/Crediting/asset-data-view';
import { periodData } from '../fn/crediting-data/period-data';
import { PeriodData$Params } from '../fn/crediting-data/period-data';

@Injectable({ providedIn: 'root' })
export class CreditingDataService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `assetData()` */
  static readonly AssetDataPath = '/crediting/data/asset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assetData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assetData$Response(params?: AssetData$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingAssetDataView>>> {
    return assetData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assetData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assetData(params?: AssetData$Params, context?: HttpContext): Observable<Array<LuklaCreditingAssetDataView>> {
    return this.assetData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingAssetDataView>>): Array<LuklaCreditingAssetDataView> => r.body)
    );
  }

  /** Path part for operation `assetsData()` */
  static readonly AssetsDataPath = '/crediting/data/assets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `assetsData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assetsData$Response(params?: AssetsData$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingAssetDataView>>> {
    return assetsData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `assetsData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  assetsData(params?: AssetsData$Params, context?: HttpContext): Observable<Array<LuklaCreditingAssetDataView>> {
    return this.assetsData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingAssetDataView>>): Array<LuklaCreditingAssetDataView> => r.body)
    );
  }

  /** Path part for operation `periodData()` */
  static readonly PeriodDataPath = '/crediting/data/period';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `periodData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  periodData$Response(params?: PeriodData$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingAggregationDataView>>> {
    return periodData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `periodData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  periodData(params?: PeriodData$Params, context?: HttpContext): Observable<Array<LuklaCreditingAggregationDataView>> {
    return this.periodData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingAggregationDataView>>): Array<LuklaCreditingAggregationDataView> => r.body)
    );
  }

}
