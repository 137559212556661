/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { attachOttotasksItem } from '../fn/assets/attach-ottotasks-item';
import { AttachOttotasksItem$Params } from '../fn/assets/attach-ottotasks-item';
import { changeDates } from '../fn/assets/change-dates';
import { ChangeDates$Params } from '../fn/assets/change-dates';
import { create } from '../fn/assets/create';
import { Create$Params } from '../fn/assets/create';
import { createBulk } from '../fn/assets/create-bulk';
import { CreateBulk$Params } from '../fn/assets/create-bulk';
import { find } from '../fn/assets/find';
import { Find$Params } from '../fn/assets/find';
import { getPeriods } from '../fn/assets/get-periods';
import { GetPeriods$Params } from '../fn/assets/get-periods';
import { getVerifications } from '../fn/assets/get-verifications';
import { GetVerifications$Params } from '../fn/assets/get-verifications';
import { listAssets } from '../fn/assets/list-assets';
import { ListAssets$Params } from '../fn/assets/list-assets';
import { AssetGridItem as LuklaCreditingAssetGridItem } from '../models/Lukla/Crediting/asset-grid-item';
import { AssetView as LuklaCreditingAssetView } from '../models/Lukla/Crediting/asset-view';
import { DetailedVerificationView as LuklaCreditingDetailedVerificationView } from '../models/Lukla/Crediting/detailed-verification-view';
import { PeriodItem as LuklaCreditingPeriodItem } from '../models/Lukla/Crediting/period-item';

@Injectable({ providedIn: 'root' })
export class AssetsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `create()` */
  static readonly CreatePath = '/assets/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<string> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `createBulk()` */
  static readonly CreateBulkPath = '/assets/create/bulk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBulk()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBulk$Response(params?: CreateBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return createBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBulk$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createBulk(params?: CreateBulk$Params, context?: HttpContext): Observable<void> {
    return this.createBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `attachOttotasksItem()` */
  static readonly AttachOttotasksItemPath = '/assets/attach/ottotask/item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachOttotasksItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachOttotasksItem$Response(params?: AttachOttotasksItem$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attachOttotasksItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attachOttotasksItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  attachOttotasksItem(params?: AttachOttotasksItem$Params, context?: HttpContext): Observable<void> {
    return this.attachOttotasksItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `find()` */
  static readonly FindPath = '/assets/find/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params: Find$Params, context?: HttpContext): Observable<StrictHttpResponse<LuklaCreditingAssetView>> {
    return find(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params: Find$Params, context?: HttpContext): Observable<LuklaCreditingAssetView> {
    return this.find$Response(params, context).pipe(
      map((r: StrictHttpResponse<LuklaCreditingAssetView>): LuklaCreditingAssetView => r.body)
    );
  }

  /** Path part for operation `changeDates()` */
  static readonly ChangeDatesPath = '/assets/dates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeDates()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeDates$Response(params?: ChangeDates$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return changeDates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeDates$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  changeDates(params?: ChangeDates$Params, context?: HttpContext): Observable<void> {
    return this.changeDates$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getPeriods()` */
  static readonly GetPeriodsPath = '/assets/{id}/periods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPeriods()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeriods$Response(params: GetPeriods$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingPeriodItem>>> {
    return getPeriods(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPeriods$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPeriods(params: GetPeriods$Params, context?: HttpContext): Observable<Array<LuklaCreditingPeriodItem>> {
    return this.getPeriods$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingPeriodItem>>): Array<LuklaCreditingPeriodItem> => r.body)
    );
  }

  /** Path part for operation `getVerifications()` */
  static readonly GetVerificationsPath = '/assets/{id}/verifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVerifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerifications$Response(params: GetVerifications$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingDetailedVerificationView>>> {
    return getVerifications(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVerifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVerifications(params: GetVerifications$Params, context?: HttpContext): Observable<Array<LuklaCreditingDetailedVerificationView>> {
    return this.getVerifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingDetailedVerificationView>>): Array<LuklaCreditingDetailedVerificationView> => r.body)
    );
  }

  /** Path part for operation `listAssets()` */
  static readonly ListAssetsPath = '/assets/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listAssets()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAssets$Response(params?: ListAssets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingAssetGridItem>>> {
    return listAssets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listAssets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listAssets(params?: ListAssets$Params, context?: HttpContext): Observable<Array<LuklaCreditingAssetGridItem>> {
    return this.listAssets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingAssetGridItem>>): Array<LuklaCreditingAssetGridItem> => r.body)
    );
  }

}
