/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { confirmValidatorAccess } from '../fn/validation-access/confirm-validator-access';
import { ConfirmValidatorAccess$Params } from '../fn/validation-access/confirm-validator-access';
import { getValidators } from '../fn/validation-access/get-validators';
import { GetValidators$Params } from '../fn/validation-access/get-validators';
import { ValidatorView as LuklaCreditingValidatorView } from '../models/Lukla/Crediting/validator-view';
import { registerValidator } from '../fn/validation-access/register-validator';
import { RegisterValidator$Params } from '../fn/validation-access/register-validator';
import { revokeValidator } from '../fn/validation-access/revoke-validator';
import { RevokeValidator$Params } from '../fn/validation-access/revoke-validator';

@Injectable({ providedIn: 'root' })
export class ValidationAccessService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getValidators()` */
  static readonly GetValidatorsPath = '/access/validation/{validationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValidators()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValidators$Response(params: GetValidators$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LuklaCreditingValidatorView>>> {
    return getValidators(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValidators$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValidators(params: GetValidators$Params, context?: HttpContext): Observable<Array<LuklaCreditingValidatorView>> {
    return this.getValidators$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LuklaCreditingValidatorView>>): Array<LuklaCreditingValidatorView> => r.body)
    );
  }

  /** Path part for operation `registerValidator()` */
  static readonly RegisterValidatorPath = '/access/validation/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerValidator()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerValidator$Response(params?: RegisterValidator$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registerValidator(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerValidator$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerValidator(params?: RegisterValidator$Params, context?: HttpContext): Observable<void> {
    return this.registerValidator$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `revokeValidator()` */
  static readonly RevokeValidatorPath = '/access/validation/revoke';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `revokeValidator()` instead.
   *
   * This method doesn't expect any request body.
   */
  revokeValidator$Response(params?: RevokeValidator$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return revokeValidator(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `revokeValidator$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  revokeValidator(params?: RevokeValidator$Params, context?: HttpContext): Observable<void> {
    return this.revokeValidator$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `confirmValidatorAccess()` */
  static readonly ConfirmValidatorAccessPath = '/access/validation/confirm/{validationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmValidatorAccess()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirmValidatorAccess$Response(params: ConfirmValidatorAccess$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return confirmValidatorAccess(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `confirmValidatorAccess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  confirmValidatorAccess(params: ConfirmValidatorAccess$Params, context?: HttpContext): Observable<boolean> {
    return this.confirmValidatorAccess$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
