<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="btn close pull-right" aria-label="Close" (click)="cancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="alert alert-{{color}} d-flex align-items-start status-item" >
        <fa-icon class="status-icon" [icon]="icon"></fa-icon>
        <div>
            <h3 class="status-header">{{title}}</h3>
            <p>{{confirmText}}</p>   
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="cancel()">{{closeBtnName}}</button>
    <button type="button" class="btn btn-success" (click)="confirm()">{{confirmBtnName}}</button>
</div>